import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";

//Import Styles
import "./Dashboard.scss";

//Import Actions
import { fetchVehicles } from "actions/dashboardActions";
import { fetchVehiclesServiceData } from "actions/serviceActions";

//Import Components
import VehicleCard from "components/VehicleCard";

//https://github.com/diogofcunha/react-virtualized-tree/
function Dashboard(props) {
  useEffect(() => {
    const gVehicles = async () => {
      props.getServiceData();
    };
    gVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let vList = <div>No vehicle</div>;
  if (props.vehicles) {
    vList = props.servicevehicles
      .filter((x) => x.total_distance > 0)
      .map((v) => (
        <Grid.Column key={v.VIN}>
          <VehicleCard vehicle={v} key={v.VIN} />
        </Grid.Column>
      ));
  }

  return (
    <Grid stackable className="main" container>
      <Grid.Row columns={4}>{vList}</Grid.Row>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  vehicles: state.dashboard.vehicles,
  servicevehicles: state.service.servicevehicles,
});
const mapDispatchToProps = (dispatch) => ({
  getVehicles: () => dispatch(fetchVehicles()),
  getServiceData: () => dispatch(fetchVehiclesServiceData()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
