import React from "react";
import {Grid,Header} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next'

//Import Styles
import './Home.scss';
//import korhonenlogo from 'images/brands/korhonen.png';

const Home = () => {
  const { t } = useTranslation()
  
  return (
    <Grid textAlign='center' className='main' container>
      <Grid.Row>
        <Grid.Column>
          {/*<Image src={korhonenlogo} size='medium' centered/>*/}
          <Header as='h2' icon textAlign='center'>
            <Header.Content>{t('home.title')}</Header.Content>
          </Header>
          </Grid.Column>
      </Grid.Row>
    </Grid>
    
  );
};

export default Home;