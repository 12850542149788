import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useHistory} from "react-router-dom";


function Reports(props) {
    const history = useHistory();

    const pbiUrl = "https://app.powerbi.com/reportEmbed?reportId=511fb193-1296-4770-bba2-6b510781b97d&autoAuth=true&ctid=85b8a547-2932-4343-af48-a453dd4d81cc"
    // const pbiUrl = "https://app.powerbi.com/reportEmbed?reportId=bb59145d-6e0b-4655-8e3a-2715809af22e&autoAuth=true&ctid=172ddbfc-51b6-4a67-8fbc-5aad5b3e3666&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D"

    useEffect(() => {
        handleRedirectPowerBI()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const handleRedirectPowerBI = () => {
        window.open(pbiUrl, '_blank');
        history.push("/");
    }

    return (
        < >
        </>
    );
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
});
export default connect(
    mapStateToProps,mapDispatchToProps
)(Reports)