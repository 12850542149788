import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, Form, Message, List } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Import Actions
import {
  fetchRevenueGroups,
  fetchUngroupedRevenueTypes,
  addGroupToRevenueType,
} from "actions/inputActions";

//Import Components
import ActionButton from "components/ActionButton";

//Import Styles

function LinkUngroupedRevenuetypes(props) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    group_id: 0,
    revenuetype_id: 0,
  });
  const [errorList, setErrorList] = useState([]);

  const onDropDownChanged = (e, { value, name }) => {
    setFormData({ ...formData, [name]: value });
  };

  const gRevenuegroups = async () => {
    props.getRevenuegroups();
  };

  const gUngroupedRevenuegroups = async () => {
    props.getUngroupedRevenuegroups();
  };

  const onLinkGroup = () => {
    let linkData = {
      group_id: formData.group_id,
      revenuetypes: [formData.revenuetype_id],
    };
    let eList = [];
    //Check the data
    if (formData.group_id === 0) {
      eList.push(t("input.linkungroupedrevenuetypes.errors.missing_group"));
    }
    if (formData.revenuetype_id === 0) {
      eList.push(
        t("input.linkungroupedrevenuetypes.errors.missing_revenuetype")
      );
    }
    setErrorList(eList);
    if (eList.length === 0) {
      props.addLink(linkData).then((x) => {
        resetForm();
        gUngroupedRevenuegroups();
      });
    }
  };

  const resetForm = () => {
    setFormData({
      group_id: 0,
      revenuetype_id: 0,
    });
  };

  useEffect(() => {
    gRevenuegroups();
    gUngroupedRevenuegroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const revenuegroupList = props.revenuegroups.map((x) => {
    return { key: x.id, value: x.id, text: x.name };
  });

  const ungroupedrevenuetypesList = props.ungroupedrevenuetypes.map((x) => {
    return { key: x.id, value: x.id, text: x.name };
  });

  let errorMessages = <div></div>;
  if (errorList.length > 0) {
    errorMessages = (
      <Message negative>
        <Message.Header>
          {t("input.linkungroupedrevenuetypes.errors.title")}
        </Message.Header>
        <List bulleted>
          {errorList.map((x, i) => {
            return <List.Item key={i}>{x}</List.Item>;
          })}
        </List>
      </Message>
    );
  }
  return (
    <Card fluid className="linkungroupedrevenuetypes">
      <Card.Content>
        <Card.Header>{t("input.linkungroupedrevenuetypes.title")}</Card.Header>
      </Card.Content>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Dropdown
              required
              id="linkungroupedrevenuetypes-group_id"
              label={t("input.linkungroupedrevenuetypes.select_group")}
              fluid
              search
              selection
              options={revenuegroupList}
              value={formData.group_id}
              onChange={onDropDownChanged}
              lazyLoad={true}
              name="group_id"
            />
            <Form.Dropdown
              required
              id="linkungroupedrevenuetypes-revenuetype_id"
              label={t("input.linkungroupedrevenuetypes.select_revenuetype")}
              fluid
              search
              selection
              options={ungroupedrevenuetypesList}
              onChange={onDropDownChanged}
              value={formData.revenuetype_id}
              lazyLoad={true}
              name="revenuetype_id"
            />
          </Form.Group>
          {errorMessages}
          <ActionButton
            text={t("input.linkungroupedrevenuetypes.button_link")}
            size="large"
            icon="save"
            onClick={onLinkGroup}
          />
        </Form>
      </Card.Content>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  revenuegroups: state.input.revenuegroups,
  ungroupedrevenuetypes: state.input.ungroupedrevenuetypes,
});
const mapDispatchToProps = (dispatch) => ({
  getRevenuegroups: () => dispatch(fetchRevenueGroups()),
  getUngroupedRevenuegroups: () => dispatch(fetchUngroupedRevenueTypes()),
  addLink: (linkdata) => dispatch(addGroupToRevenueType(linkdata)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkUngroupedRevenuetypes);
