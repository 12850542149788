import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, Form, Message, List } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Import Actions
import {
  fetchCostGroups,
  fetchUngroupedCostTypes,
  addGroupToCostType,
} from "actions/inputActions";

//Import Components
import ActionButton from "components/ActionButton";

//Import Styles

function LinkUngroupedCosttypes(props) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    group_id: 0,
    costtype_id: 0,
  });
  const [errorList, setErrorList] = useState([]);

  const onDropDownChanged = (e, { value, name }) => {
    setFormData({ ...formData, [name]: value });
  };

  const gCostgroups = async () => {
    props.getCostgroups();
  };

  const gUngroupedCostgroups = async () => {
    props.getUngroupedCostgroups();
  };

  const onLinkGroup = () => {
    let linkData = {
      group_id: formData.group_id,
      costtypes: [formData.costtype_id],
    };
    let eList = [];
    //Check the data
    if (formData.group_id === 0) {
      eList.push(t("input.linkungroupedcosttypes.errors.missing_group"));
    }
    if (formData.costtype_id === 0) {
      eList.push(t("input.linkungroupedcosttypes.errors.missing_costtype"));
    }
    setErrorList(eList);
    if (eList.length === 0) {
      props.addLink(linkData).then((x) => {
        resetForm();
        gUngroupedCostgroups();
      });
    }
  };

  const resetForm = () => {
    setFormData({
      group_id: 0,
      costtype_id: 0,
    });
  };

  useEffect(() => {
    gCostgroups();
    gUngroupedCostgroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const costgroupList = props.costgroups.map((x) => {
    return { key: x.id, value: x.id, text: x.name };
  });

  const ungroupedcosttypesList = props.ungroupedcosttypes.map((x) => {
    return { key: x.id, value: x.id, text: x.name };
  });

  let errorMessages = <div></div>;
  if (errorList.length > 0) {
    errorMessages = (
      <Message negative>
        <Message.Header>
          {t("input.linkungroupedcosttypes.errors.title")}
        </Message.Header>
        <List bulleted>
          {errorList.map((x, i) => {
            return <List.Item key={i}>{x}</List.Item>;
          })}
        </List>
      </Message>
    );
  }
  return (
    <Card fluid className="linkungroupedcosttypes">
      <Card.Content>
        <Card.Header>{t("input.linkungroupedcosttypes.title")}</Card.Header>
      </Card.Content>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Dropdown
              required
              id="linkungroupedcosttypes-group_id"
              label={t("input.linkungroupedcosttypes.select_group")}
              fluid
              search
              selection
              options={costgroupList}
              value={formData.group_id}
              onChange={onDropDownChanged}
              lazyLoad={true}
              name="group_id"
            />
            <Form.Dropdown
              required
              id="linkungroupedcosttypes-costtype_id"
              label={t("input.linkungroupedcosttypes.select_costtype")}
              fluid
              search
              selection
              options={ungroupedcosttypesList}
              onChange={onDropDownChanged}
              value={formData.costtype_id}
              lazyLoad={true}
              name="costtype_id"
            />
          </Form.Group>
          {errorMessages}
          <ActionButton
            text={t("input.linkungroupedcosttypes.button_link")}
            size="large"
            icon="save"
            onClick={onLinkGroup}
          />
        </Form>
      </Card.Content>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  costgroups: state.input.costgroups,
  ungroupedcosttypes: state.input.ungroupedcosttypes,
});
const mapDispatchToProps = (dispatch) => ({
  getCostgroups: () => dispatch(fetchCostGroups()),
  getUngroupedCostgroups: () => dispatch(fetchUngroupedCostTypes()),
  addLink: (linkdata) => dispatch(addGroupToCostType(linkdata)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkUngroupedCosttypes);
