import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'semantic-ui-react'
import { useCookies} from 'react-cookie';
import './Navbar.scss';


function NavbarLang(props){
    const cookieName = 'tmsfleetas-lang';
    const defaultLang = 'en'
    const [languageOptions] = useState(
        [
            { key: 'en', value: 'en', flag: 'uk', text: 'English'},
            { key: 'fi', value: 'fi', flag: 'fi', text: 'Finnish'},
        ]
    );
    const [selectedOption,setSelectedOption] = useState(defaultLang);
    const [cookies, setCookie] = useCookies([cookieName]);

    const { i18n } = useTranslation();

    //this triggers every time cookies array change
    useEffect(()=>{
        let foundLangValue = languageOptions.find(x=>x.value===cookies[cookieName])
        if (!foundLangValue){
            foundLangValue = languageOptions[0];
        }
        i18n.changeLanguage(foundLangValue.value);   
        setSelectedOption(foundLangValue.value);
    },[i18n,cookies,languageOptions]);

    const languageChanged = (e, { value }) => {
        setCookie(cookieName,value,{sameSite:"Strict"});
    }

    return(
            <Dropdown
                button
                className='icon'
                labeled
                icon='world'
                options={languageOptions}
                onChange={languageChanged}
                value={selectedOption}
            />
    );
}


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
})

export default connect(
    mapStateToProps,mapDispatchToProps
)(NavbarLang);