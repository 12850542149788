import React from 'react';
import {Modal,Header,Icon,Button} from 'semantic-ui-react';
import { useTranslation } from "react-i18next";

import './ModalError.scss';

//Import Actions
import {MODAL_CONFIRM_NO} from 'actions/rootActions';

function ModalConfirm (props){
    const { t } = useTranslation();
   
    return(
        <Modal open={true} className="modalConfirm" basic>
            <Header icon='question' content={t('modal.confirm.header')} />
            <Modal.Content>
                {props.modal.message}
            </Modal.Content>
            <Modal.Actions>
            <Button basic inverted color='red' onClick={()=>{props.onModalButtonClick(MODAL_CONFIRM_NO,{})}}>
                    <Icon name='remove' /> {t('modal.confirm.no')}
                </Button>
                <Button inverted color='green' onClick={props.modal.func}>
                    <Icon name='checkmark' /> {t('modal.confirm.yes')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ModalConfirm;