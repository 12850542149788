import React, { Suspense } from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { routerMiddleware, ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import history from "utils/history";
import { useAuth0 } from "@auth0/auth0-react";
import { CookiesProvider } from "react-cookie";

import "i18n";

//Import Containers
import Home from "containers/Home";
import Input from "containers/Input";
import Reports from "containers/Reports";
import Data from "containers/Data";
import Dashboard from "containers/Dashboard";
import Service from "containers/Service";
import ServiceView from "containers/ServiceView";
import AddService from "containers/AddService";
import EditVehicle from "containers/EditVehicle";
import ViewServices from "containers/ViewServices";
//Import Components
import PrivateRoute from "components/PrivateRoute";
import Navbar from "components/Navbar";
import ModalMain from "components/ModalMain";
import LoadingOverlay from "components/LoadingOverlay";

//Import Reducer
import rootReducer from "reducers/rootReducer";

import { sec } from "utils/security";
import Reactotron from '../ReactotronConfig'


//Import Styles
import "./App.scss";
import VehicleStatus from "./VehicleStatus";

//Create redux store
const appStore = createStore(
  rootReducer(history),
  compose(applyMiddleware(routerMiddleware(history), thunk), Reactotron.createEnhancer())
);

const App = () => {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  sec.setAccessTokenSilently(getAccessTokenSilently);

  if (isLoading) {
    return (
      <Provider store={appStore}>
        <LoadingOverlay loading={true} />
      </Provider>
    );
  }
  return (
    <Provider store={appStore}>
      <BrowserRouter>
        <ConnectedRouter history={history}>
          <div className="app-wrapper">
            <Suspense fallback={null}>
              <Helmet
                titleTemplate="%s - ms Fleet Analytics Suite"
                defaultTitle="ms Fleet Analytics Suite"
              >
                <meta name="description" content="ms Fleet Analytics Suite" />
              </Helmet>
              <Navbar location={history.location} />
              <Switch>
                <PrivateRoute
                  exact
                  path="/input"
                  component={Input}
                  key="input"
                />
                <PrivateRoute
                  exact
                  path="/reports"
                  component={Reports}
                  key="reports"
                />
                <PrivateRoute exact path="/data" component={Data} key="data" />
                <PrivateRoute
                  exact
                  path="/dashboard"
                  component={Dashboard}
                  key="dashboard"
                />
                <PrivateRoute
                  exact
                  path="/service"
                  component={Service}
                  key="service"
                />
                <PrivateRoute
                  exact
                  path="/dashboard"
                  component={Dashboard}
                  key="dashboard"
                />
                <PrivateRoute
                  exact
                  path="/serviceview"
                  component={ServiceView}
                  key="serviceview"
                />
                <PrivateRoute
                  exact
                  path="/addservice"
                  component={AddService}
                  key="addservice"
                />
                <PrivateRoute
                  exact
                  path="/editvehicle"
                  component={EditVehicle}
                  key="editvehicle"
                />
                <Route
                  exact
                  path="/vehiclestatus"
                  component={VehicleStatus}
                  key="vehiclestatus"
                />
                <PrivateRoute
                  exact
                  path="/viewservices"
                  component={ViewServices}
                  key="viewservices"
                />
                <Route component={Home} />

              </Switch>
              <ToastContainer />
              <ModalMain />
              <LoadingOverlay />
              <CookiesProvider />
            </Suspense>
          </div>
        </ConnectedRouter>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
