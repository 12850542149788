import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Button,Icon} from 'semantic-ui-react';

class ActionButton extends React.Component{

    render(){
        if(!this.props.animated){
            return(
                <Button color={this.props.color} icon={this.props.icon} size={this.props.size} compact key={this.props.actionType}
                    content={this.props.text} onClick={()=>{this.props.onClick(this.props)}} fluid>
                 </Button>
            )
        }else{
            return(
                <Button animated color={this.props.color} size={this.props.size} compact key={this.props.actionType}
                    onClick={()=>{this.props.onClick(this.props)}} fluid>
                    <Button.Content visible>{this.props.text}</Button.Content>
                    <Button.Content hidden>
                        <Icon name={this.props.icon} loading={this.props.loading}/>
                    </Button.Content>
                 </Button>
            )
        }
        
    }
}

ActionButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    color: PropTypes.oneOf(['green','red','blue','grey']),
    size:PropTypes.oneOf(["mini","tiny","small","medium","large","big","huge","massive"]),
    text:PropTypes.string,
    icon:PropTypes.string,
    actionType:PropTypes.string,
    loading:PropTypes.bool,
}
  
ActionButton.defaultProps = {
    color:'green',
    size:'small',
    icon:'question'
}

export default connect()(ActionButton)