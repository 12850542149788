import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Table } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

//Import Styles
import "./MaintenanceHistory.scss";
//Import actions
import { fetchVehiclesServiceHistory } from "actions/serviceActions";
//Import utils
import { dateStr, dateOnly } from "utils/utils";

const MaintenanceHistory = ({ vin }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch()
  const servicehistory = useSelector(state => {
    return state.service.servicehistory
  })
  useEffect(() => {
    dispatch(fetchVehiclesServiceHistory(vin))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vin]);

  const handleEditClick = (e, { id }) => {
    // Find the related object
    const data = servicehistory.find((elem) => elem.id === id);
    //history.push("/serviceview", { data });
  };

  return (
    <div className="main">
      <Table celled compact padded striped singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="4">
              {t("maintenancehistory.service_date")}
            </Table.HeaderCell>
            <Table.HeaderCell width="2">
              {t("maintenancehistory.total_mileage")}
            </Table.HeaderCell>
            <Table.HeaderCell width="10">
              {t("maintenancehistory.service_type")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {servicehistory && servicehistory.map((x, key) => {
            return (
              <Table.Row key={key}>
                <Table.Cell>{dateOnly(x.date)}</Table.Cell>
                <Table.Cell>{x.km}</Table.Cell>
                <Table.Cell>{x.service_type}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  servicehistory: state.service.servicehistory,
});
const mapDispatchToProps = (dispatch) => ({
  getServiceHistory: (vin) => dispatch(fetchVehiclesServiceHistory(vin)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceHistory);
