import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Card, Grid, Button, Image, List, Label } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const ServiceInfo = ({ serviceID = "", regNumber = "" }) => {
    const { t } = useTranslation();
    return <Grid style={{ marginBottom: 10 }} columns={2}>
        <Grid.Column width="6">
            <Card fluid>
                <Card.Content>
                    <Card.Header>{t("serviceview.title")}</Card.Header>
                </Card.Content>
                <Card.Content>
                    <Grid columns={2}>
                        {/* <Grid.Row>
                            <Grid.Column className="label">
                                {t("serviceview.reg_number")}
                            </Grid.Column>
                            <Grid.Column className="label">
                                {regNumber}
                            </Grid.Column>
                        </Grid.Row> */}
                        <Grid.Row>
                            <Grid.Column className="label">
                                {t("serviceview.vehicle_id")}
                            </Grid.Column>
                            <Grid.Column className="label">
                                {serviceID}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
            </Card>
        </Grid.Column>
    </Grid>
}

export default ServiceInfo