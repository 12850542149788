import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Table } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { Card, Grid, Button, Image, List, Label } from "semantic-ui-react";
import { getStatusIcon } from "utils/serviceUtils";

//Import Styles
import "./AxelsView.scss";
//Import actions
import { fetchVehiclesServiceAxels } from "actions/serviceActions";
//Import utils
import { dateStr, kmData, formatDateTime, dateOnly } from "utils/utils";

const MaintenanceHistory = ({ id, serviceAxels, getServiceAxels, data }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const totalVehicleMileage = data.total_distance;
  const vehicleMaintenance = data.lastService;
  const vehicleServiceInterval = data.serviceInterval;
  const serviceAlert = data.nextservicealert;

  const renderLastChange = (serviceAxels, axelKey) => {
    let lastChangeDataIndex = serviceAxels[axelKey].findIndex((item) =>
      item.hasOwnProperty("last_change")
    );
    let lastChangeData =
      serviceAxels[axelKey][lastChangeDataIndex]["last_change"];
    return ["tires", "break_pad", "break_disk", "air_bag"].map(
      (part, index) => {
        let lastService = lastChangeData[part]["last_service"];
        let serviceInterval = lastChangeData[part]["service_interval"];
        let lastServiceTime = lastChangeData[part]["last_service_time"];
        return (
          <>
            <AxelHeader title={`${lastService} km`} />
            <Grid.Column>
              <p>{dateOnly(lastServiceTime)}</p>
            </Grid.Column>
          </>
        );
      }
    );
  };

  const renderNextChange = (serviceAxels, axelKey, totalKm) => {
    let lastChangeDataIndex = serviceAxels[axelKey].findIndex((item) =>
      item.hasOwnProperty("last_change")
    );
    let lastChangeData =
      serviceAxels[axelKey][lastChangeDataIndex]["last_change"];

    return ["tires", "break_pad", "break_disk", "air_bag"].map(
      (part, index) => {
        let lastService = lastChangeData[part]["last_service"];
        let serviceInterval = lastChangeData[part]["service_interval"];
        let lastServiceTime = lastChangeData[part]["last_service_time"];
        let nextService = lastService + serviceInterval;
        let CHK = lastService + serviceInterval - totalKm;
        return (
          <>
            <AxelHeader title={`${nextService} km`} />
            <Grid.Column>
              {getStatusIcon(CHK, nextService, "text", t).getIcon()}
            </Grid.Column>
          </>
        );
      }
    );
  };

  useEffect(() => {
    const gServiceData = async () => {
      getServiceAxels(id);
    };
    gServiceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="main">
      {!!serviceAxels &&
        Object.keys(serviceAxels).map((axelKey, axelIndex) => {
          return (
            <Grid.Row className="axel_row" key={axelIndex}>
              <Grid.Column width="15">
                <Card fluid>
                  <Card.Content>
                    <Card.Header>
                      {axelKey.replace(/(Axel)/gi, t("axelstatus.axel"))}
                    </Card.Header>
                  </Card.Content>

                  <Card.Content>
                    <Grid columns={9}>
                      <Grid.Row>
                        <Grid.Column></Grid.Column>

                        <AxelHeader
                          title={t("vehiclestatus.tires")}
                          numSpaceColumn={1}
                        />
                        <Grid.Column></Grid.Column>

                        <AxelHeader
                          title={t("vehiclestatus.breakpads")}
                          numSpaceColumn={1}
                        />
                        <Grid.Column></Grid.Column>

                        <AxelHeader
                          title={t("vehiclestatus.breakdisks")}
                          numSpaceColumn={1}
                        />
                        <Grid.Column></Grid.Column>

                        <AxelHeader
                          title={t("vehiclestatus.airbags")}
                          numSpaceColumn={1}
                        />
                        <Grid.Column></Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Card.Header className="label">
                            {t("vehiclestatus.last_changed")}
                          </Card.Header>
                        </Grid.Column>
                        {renderLastChange(serviceAxels, axelKey)}
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Card.Header className="label">
                            {t("vehiclestatus.next_change")}
                          </Card.Header>
                        </Grid.Column>
                        {renderNextChange(
                          serviceAxels,
                          axelKey,
                          data["total_distance"] / 1000
                        )}
                      </Grid.Row>
                    </Grid>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
          );
        })}
    </div>
  );
};

const AxelHeader = ({ title, numSpaceColumn }) => {
  return (
    <>
      <Grid.Column>
        <Card.Header className="label">{title}</Card.Header>
      </Grid.Column>
      {/* {Array(numSpaceColumn).fill(1).map(value => <Grid.Column></Grid.Column>)} */}
    </>
  );
};

const mapStateToProps = (state) => ({
  serviceAxels: state.service.serviceAxels,
});
const mapDispatchToProps = (dispatch) => ({
  getServiceAxels: (id) => dispatch(fetchVehiclesServiceAxels(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceHistory);
