import React from 'react';
import {Modal,Header,Icon,Button,Message} from 'semantic-ui-react';

import './ModalError.scss';

//Import Actions
import {MODAL_ERROR_OK} from 'actions/rootActions';

function ModalError (props){
    let message = props.modal
    if (message.response){
        message=props.modal.response.data
    }
    return(
        <Modal open={true} className="modalError">
            <Header icon='times' content='An error has occured' />
            <Modal.Content>
                <Message negative>
                    <Message.Header>{message.message}</Message.Header>
                    <pre>{JSON.stringify(message.details,undefined,2)}</pre>
                </Message>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={()=>{props.onModalButtonClick(MODAL_ERROR_OK,{})}}>
                    <Icon name='remove' /> OK
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ModalError;