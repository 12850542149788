//Import Actions
import { loading, modalError } from "./rootActions";

//Import API
import { getVehicles } from "api/backendAPI";

export const GET_VEHICLES_SUCCESS = "GET_VEHICLES_SUCCESS";

function fetchVehicles() {
  return (dispatch) => {
    dispatch(loading(true));
    return getVehicles()
      .then((vehicles) => {
        dispatch(loading(false));
        dispatch(fetchVehiclesSuccess(vehicles));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function fetchVehiclesSuccess(vehicles) {
  return {
    type: GET_VEHICLES_SUCCESS,
    vehicles,
  };
}

export { fetchVehicles };
