import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, Form, Message, List } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Import Actions
import {
  fetchUnlinkedRevenues,
  addLinkVehicleToRevenue,
} from "actions/inputActions";

//Import Components
import ActionButton from "components/ActionButton";

//Import Styles
import "./InputLinkRevenuesForm.scss";

function InputLinkRevenuesForm(props) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    vehicle_id: 0,
    revenue_id: 0,
    total_cost: 0,
    currency: "EUR",
  });
  const [errorList, setErrorList] = useState([]);
  let [revenueSelection, setRevenueSelection] = useState([]);

  const onDropDownChanged = (e, { value, name }) => {
    if (name === "revenue_id") {
      let revenue = revenuesGroupedByGig.get(value);
      let total_cost = 0;
      let currency = "EUR";
      // We pick the first currency - no mixes supported
      if (revenue.length > 0) {
        currency = revenue[0].currency ? revenue[0].currency : "EUR";
      }
      revenue.forEach((item) => {
        total_cost += item.total;
      });
      setFormData({ ...formData, total_cost, currency });
      setRevenueSelection(revenue);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const onLinkVehicle = () => {
    let linkData = {
      vehicle_id: formData.vehicle_id,
      revenue_id: revenueSelection.map((x, i) => {
        return x.id;
      }),
    };
    let eList = [];
    //Check the data
    if (linkData.vehicle_id === 0) {
      eList.push(t("input.linkrevenuesform.errors.missing_vehicle"));
    }
    if (linkData.revenue_id === 0) {
      eList.push(t("input.linkrevenuesform.errors.missing_link"));
    }
    setErrorList(eList);
    if (eList.length === 0) {
      props.addLink(linkData).then((x) => {
        resetForm();
        const gUnlinkedRevenues = async () => {
          props.getUnlinkedRevenues();
        };
        gUnlinkedRevenues();
      });
    }
  };

  const resetForm = () => {
    setFormData({
      vehicle_id: 0,
      revenue_id: 0,
      total_cost: 0,
      currency: "EUR",
    });
    setRevenueSelection([]);
  };

  useEffect(() => {
    const gUnlinkedRevenues = async () => {
      props.getUnlinkedRevenues();
    };
    gUnlinkedRevenues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const vehicleList = props.vehicles.map((x) => {
    return { value: x.id, text: `${x.brand} - ${x.reg_number}` };
  });

  const unlinkedrevenuesList = [];
  // Create Map object with revenues data for quick access
  const revenuesGroupedByGig = new Map();

  props.unlinkedrevenues.forEach((element) => {
    // Build revenues grouped by gig
    let list = revenuesGroupedByGig.get(element.spare1);
    if (list === undefined) {
      list = [];
      list.push(element);
      revenuesGroupedByGig.set(element.spare1, list);
      unlinkedrevenuesList.push({
        value: element.spare1,
        text: `${element.spare1}`,
      });
    } else {
      list.push(element);
      revenuesGroupedByGig.set(element.spare1, list);
    }
  });

  const revenueDetail = (revenue) => {
    let revenueRow = "";
    if (revenue) {
      let invoiceDate = new Date(revenue.invoicedate);
      // We hard code these to DSV for now - that's the only possible source for unlinked
      // revenues for now
      revenueRow = `DSV: ${t(
        "input.linkrevenuesform.revenues.invoicedate"
      )}: ${invoiceDate.toLocaleDateString("fi-FI")} 
     ${t("input.linkrevenuesform.revenues.total")}: ${revenue.total} ${
        revenue.currency
      }`;
    }
    return revenueRow;
  };

  let revenueDetails = <div></div>;
  if (revenueSelection.length > 0) {
    revenueDetails = (
      <Message positive>
        <Message.Header>
          {t("input.linkrevenuesform.revenues.title")}
        </Message.Header>
        <List bulleted>
          {revenueSelection.map((x, i) => {
            return <List.Item key={i}>{revenueDetail(x)}</List.Item>;
          })}
        </List>
        <Message.Header>{`${t(
          "input.linkrevenuesform.revenues.total"
        )}: ${formData.total_cost.toFixed(2)} ${
          formData.currency
        }`}</Message.Header>
      </Message>
    );
  }

  let errorMessages = <div></div>;
  if (errorList.length > 0) {
    errorMessages = (
      <Message negative>
        <Message.Header>
          {t("input.linkrevenuesform.errors.title")}
        </Message.Header>
        <List bulleted>
          {errorList.map((x, i) => {
            return <List.Item key={i}>{x}</List.Item>;
          })}
        </List>
      </Message>
    );
  }
  return (
    <Card fluid className="inputlinkrevenuesform">
      <Card.Content>
        <Card.Header>{t("input.linkrevenuesform.link_title")}</Card.Header>
      </Card.Content>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Dropdown
              required
              id="inputlinkrevenuesform-transport-trailer"
              label={t("input.linkrevenuesform.select_transport_trailer")}
              fluid
              search
              selection
              options={unlinkedrevenuesList}
              value={formData.revenues_id}
              onChange={onDropDownChanged}
              lazyLoad={true}
              name="revenue_id"
            />
            <Form.Dropdown
              required
              id="inputlinkrevenuesform-vehicle"
              label={t("input.linkrevenuesform.select_vehicle")}
              fluid
              search
              selection
              options={vehicleList}
              value={formData.vehicle_id}
              onChange={onDropDownChanged}
              lazyLoad={true}
              name="vehicle_id"
            />
          </Form.Group>
          {revenueDetails}
          {errorMessages}
          <ActionButton
            text={t("input.linkrevenuesform.button_link")}
            size="large"
            icon="save"
            onClick={onLinkVehicle}
          />
        </Form>
      </Card.Content>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  vehicles: state.input.vehicles,
  unlinkedrevenues: state.input.unlinkedrevenues,
});
const mapDispatchToProps = (dispatch) => ({
  getUnlinkedRevenues: () => dispatch(fetchUnlinkedRevenues()),
  addLink: (linkdata) => dispatch(addLinkVehicleToRevenue(linkdata)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputLinkRevenuesForm);
