import produce from "immer";

//Import actions
import {GET_LATEST_COSTS,GET_LATEST_REVENUES,REMOVE_COST_SUCCESS,REMOVE_REVENUE_SUCCESS} from 'actions/dataActions';

const initialState={
    latestcosts: [],
    latestrevenues:[],
};

const dataReducer = (state=initialState,action)=>
    produce(state,draft=>{
        switch(action.type){
            case GET_LATEST_COSTS:
                draft.latestcosts=action.latestcosts;
                break;
            case GET_LATEST_REVENUES:
                draft.latestrevenues=action.latestrevenues;
                break;
            case REMOVE_COST_SUCCESS:
                //remove from list
                draft.latestcosts = draft.latestcosts.filter(e=>e.id !== action.cost_id);
                break;
            case REMOVE_REVENUE_SUCCESS:
                //remove from list
                draft.latestrevenues = draft.latestrevenues.filter(e=>e.id !== action.revenue_id);
                break;
            default:
                break;
        }
    });

export default dataReducer;