import React, { useEffect } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Import Actions
import { inspectCSV } from "actions/inputActions";

//Import Components
import CSVCheck from "components/CSVCheck";
import InputCostForm from "components/InputCostForm";
import InputLinkRevenuesForm from "components/InputLinkRevenuesForm";
import InputRevenueForm from "components/InputRevenueForm";
import LinkUngroupedCosttypes from "components/LinkUngroupedCosttypes";
import LinkUngroupedRevenuetypes from "components/LinkUngroupedRevenuetypes";

//Import Actions
import {
  fetchVehicles,
  fetchCompanies,
  fetchUngroupedCostTypes,
  fetchUngroupedRevenueTypes,
} from "actions/inputActions";

//Import Styles
import "./Input.scss";

function Input(props) {
  //const [fileList, setFileList] = useState([]);
  //const [allowedTypes] = useState(["text/plain"]);
  const { t } = useTranslation();
  const droppedFile = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      //check if file has result property.
      //if not, add property
      //if yes, check
      //setFileList(acceptedFiles);
      let fileData = {
        fileName: acceptedFiles[0].fileName,
        content: acceptedFiles[0],
      };
      props.processFiles(fileData);
    }
  };

  useEffect(() => {
    const gVehicles = async () => {
      props.getVehicles();
    };
    const gCompanies = async () => {
      props.getCompanies();
    };
    const gUngroupedCostgroups = async () => {
      props.getUngroupedCostgroups();
    };
    const gUngroupedRevenuegroups = async () => {
      props.getUngroupedRevenuegroups();
    };

    gVehicles();
    gCompanies();
    gUngroupedCostgroups();
    gUngroupedRevenuegroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.csvCheckResult]);

  return (
    <Grid className="main" container>
      <Grid.Column>
        <Grid.Row>
          <Dropzone onDrop={droppedFile}>
            {({ getRootProps, getInputProps }) => (
              <section className="csvdrop">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p>{t("input.csvform.drag_n_drop_info")}</p>
                </div>
              </section>
            )}
          </Dropzone>
        </Grid.Row>
        <Grid.Row>
          <CSVCheck />
        </Grid.Row>
        <Grid.Row>
          <InputCostForm />
        </Grid.Row>
        <Grid.Row>
          <InputRevenueForm />
        </Grid.Row>
        <Grid.Row>
          <Grid columns={3} divided>
            <Grid.Row>
              <Grid.Column>
                <InputLinkRevenuesForm />
              </Grid.Column>
              <Grid.Column>
                <LinkUngroupedCosttypes />
              </Grid.Column>
              <Grid.Column>
                <LinkUngroupedRevenuetypes />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  csvCheckResult: state.input.csvCheckResult,
});
const mapDispatchToProps = (dispatch) => ({
  processFiles: (fileList) => dispatch(inspectCSV(fileList)),
  getVehicles: () => dispatch(fetchVehicles()),
  getCompanies: () => dispatch(fetchCompanies()),
  getUngroupedRevenuegroups: () => dispatch(fetchUngroupedRevenueTypes()),
  getUngroupedCostgroups: () => dispatch(fetchUngroupedCostTypes()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Input);
