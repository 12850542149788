import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, TextArea } from "semantic-ui-react";
import { setDefaults, useTranslation } from "react-i18next";
import { DateInput } from "semantic-ui-calendar-react";
import { useHistory } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import {
  addServiceEvent,
  fetchVehiclesServiceAxels,
} from "actions/serviceActions";

//Import Styles
import "./AddService.scss";
import ServiceInfo from "components/ServiceInfo";
import { useDispatch, useSelector } from "react-redux";

const serviceType = [
  {
    key: "Service A",
    text: "Service A (Full)",
    value: "Service A",
  },
  {
    key: "service_b",
    text: "Service B",
    value: "Service B",
  },
  {
    key: "Yearly inspection",
    text: "Yearly inspection",
    value: "Yearly inspection",
  },
  {
    key: "Plotter inspection",
    text: "Plotter inspection",
    value: "Plotter inspection",
  },
  {
    key: "Other service",
    text: "Other service",
    value: "Other service",
  },
];

const createData = () => {
  return {
    km: 0,
    date: new Date(Date.now()).toISOString(),
    note: "",
    maintained_by: "",
  };
};

const createDataForAxel = () => {
  return {
    tires: createData(),
    break_pad: createData(),
    break_disk: createData(),
    air_bag: createData(),
  };
};

/*
Truck as who did (truckshop name), date, and what has been done (oil change, filters change, 
brake pads change, air bags change etc.)
*/
const AddService = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data } = props.location.state;

  const [formData, setFormData] = useState({
    maintainedBy: "",
    eventtime: "",
  });

  const [selectedService, setSelectedService] = useState("");
  const [selectedAxel, setSelectedAxel] = useState("");
  const [axelService, setAxelService] = useState(0);
  const [km, setKm] = useState(0);
  const [date, setDate] = useState(new Date(Date.now()).toISOString());
  const [note, setNote] = useState("");
  const [maintainedBy, setMaintainedBy] = useState("");
  const [saveData, setSaveData] = useState(null);

  const serviceAxels = useSelector((state) => {
    return state.service.serviceAxels;
  });

  useEffect(() => {
    Object.keys(serviceAxels).map((item) => {
      let index = serviceType.findIndex((i) => i.key === item);
      if (index < 0) {
        serviceType.push({
          key: item,
          text: item,
          value: item,
        });
      }
    });
  }, [serviceAxels]);

  useEffect(() => {
    dispatch(fetchVehiclesServiceAxels(data.id));
  }, []);

  const axelOptions = [
    {
      key: `tires`,
      text: `Axel ${axelService} Tires`,
      value: `tires`,
    },
    {
      key: `break_pad`,
      text: `Axel ${axelService} Brake pads `,
      value: `break_pad`,
    },
    {
      key: `break_disk`,
      text: `Axel ${axelService} Brake disks`,
      value: `break_disk`,
    },
    {
      key: `air_bag`,
      text: `Axel ${axelService} Airbags`,
      value: `air_bag`,
    },
  ];

  const onDateTimeChanged = (e, { value }) => {
    setFormData({ ...formData, eventtime: value });
  };

  const onCancel = () => {
    history.goBack();
  };

  const onAdd = () => {
    if (selectedService && km && date) {
      saveData["service_event"] = {
        ...saveData["service_event"],
        km: parseInt(km),
        date: new Date(date).toISOString(),
        note,
        maintained_by: maintainedBy,
        axel_type: selectedAxel,
        servicevehicle_id: data.id,
      };
      dispatch(addServiceEvent(data.id, saveData));
      setTimeout(() => {
        history.goBack();
      }, 2500);
    } else {
      window.alert("You need to add information");
    }
  };

  const checkIsAxel = (data) => {
    let flag = false;
    if (data === "Axel 1" || data === "Axel 2" || data === "Axel 3") {
      flag = true;
    }
    return flag;
  };

  const getNextChangeObj = (selectedService) => {
    return serviceAxels[selectedService]?.filter((item, index) =>
      item.hasOwnProperty("last_change")
    )[0]["last_change"];
  };

  const handleServiceChange = (e, { value }) => {
    let obj = {
      service_event: {},
      axel_info: null,
    };
    switch (value.includes("Axel ")) {
      case true:
        let axelNumber = value.split("Axel ")[1];
        setAxelService(axelNumber);
        obj["axel_info"] = getNextChangeObj(value);
        break;
      default:
        setAxelService(0);
        obj["service_event"] = createData();
        break;
    }
    obj["service_event"]["service_type"] = value;
    setSaveData(obj);
    setSelectedService(value);
    setKm(0);
    setDate(new Date(Date.now()).toISOString());
    setNote("");
    setMaintainedBy("");
  };

  const handleAxelChange = (e, { value }) => {
    setSelectedAxel(value);
    setKm(getNextChangeObj(selectedService)[value]["last_service"]);
  };

  const onKmChange = (e, { value }) => {
    setSaveData(
      onSaveDataChange(saveData, selectedService, "km", parseFloat(value))
    );
    selectedService && !checkIsAxel(selectedAxel) && setKm(value);
  };

  const onDateChange = (e, { value }) => {
    let dateChange = value
      ? new Date(value).toISOString()
      : new Date().toISOString();
    setSaveData(
      onSaveDataChange(saveData, selectedService, "date", dateChange)
    );
    selectedService && !checkIsAxel(selectedAxel) && setDate(value);
  };

  const onNoteChange = (e, { value }) => {
    setSaveData(onSaveDataChange(saveData, selectedService, "note", value));
    selectedService && !checkIsAxel(selectedAxel) && setNote(value);
  };

  const onMaintainedByChange = (e, { value }) => {
    setSaveData(
      onSaveDataChange(saveData, selectedService, "maintained_by", value)
    );
    selectedService && !checkIsAxel(selectedAxel) && setMaintainedBy(value);
  };

  const onSaveDataChange = (saveData, selectedService, prop, value) => {
    if (!selectedService) {
      window.alert("Please select service first!");
      return saveData;
    }

    if (checkIsAxel(selectedService) && selectedAxel === "") {
      window.alert("Please select axel first!");
      return saveData;
    }

    let obj = { ...saveData };
    switch (selectedService) {
      case "Axel 1":
      case "Axel 2":
      case "Axel 3":
        if (prop === "km") {
          obj["axel_info"][selectedAxel]["last_service"] = value;
        }
        break;
      default:
        obj["service_event"][prop] = value;
        break;
    }
    return obj;
  };

  return (
    <div className="inputservice">
      <h2>{t("addservice.title")}</h2>
      <ServiceInfo regNumber={data.reg_number} serviceID={data.reg_number} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Dropdown
          placeholder="Select vehicle service type"
          fluid
          selection
          options={serviceType}
          onChange={handleServiceChange}
          style={{
            width: "25%",
            marginRight: 32,
          }}
        />
        {axelService !== 0 && (
          <Dropdown
            placeholder="Axel"
            fluid
            selection
            options={axelOptions}
            onChange={handleAxelChange}
            style={{
              width: "25%",
            }}
          />
        )}
      </div>

      <div style={{ margin: "16px 0" }}>
        <Input
          label={t("addservice.maintained_by")}
          onChange={onMaintainedByChange}
          type={"text"}
          value={maintainedBy}
          style={{
            height: 40,
            marginRight: 16,
          }}
        />
        <Input
          label={"Km"}
          onChange={onKmChange}
          type={"number"}
          value={km}
          style={{
            height: 40,
            marginRight: 16,
          }}
        />
        <Input
          label={"Date"}
          onChange={onDateChange}
          type={"date"}
          value={date}
          style={{
            height: 40,
            marginRight: 16,
          }}
        />
        <TextArea
          placeholder={"Note"}
          value={note}
          onChange={onNoteChange}
          style={{
            position: "absolute",
          }}
        />
      </div>
      <Button type="submit" color="green" size="large" onClick={onAdd}>
        {t("editvehicle.btn_save")}
      </Button>
      <Button type="cancel" color="red" size="large" onClick={onCancel}>
        {t("viewservices.btn_back")}
      </Button>
    </div>
  );
};

export default AddService;
