
import React, {useState,useEffect} from 'react';
import {connect} from 'react-redux';
import { useTranslation } from "react-i18next";
import {Menu,Table,Button, Icon} from 'semantic-ui-react';
import moment from "moment";

//Import Styles
import './Data.scss';

import {modalConfirm} from 'actions/rootActions';
import {fetchLatestCosts,fetchLatestRevenues,removeCost,removeRevenue} from 'actions/dataActions';
function CostsTable(props){
    const { t } = useTranslation();
    const handleDeleteClick = (e,{id,info}) => { props.onDelete(id,
        `${t('data.costs.modal_delete_confirm')} ${info} `)}
    return(
        <Table compact celled padded striped singleLine>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{t('data.costs.table_header_timestamp')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('data.costs.table_header_manufacturer')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('data.costs.table_header_regnumber')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('data.costs.table_header_company')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('data.costs.table_header_costgroup')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('data.costs.table_header_costtype')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('data.costs.table_header_total')}</Table.HeaderCell>
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {props.costs.map(x=>{
                    return(
                    <Table.Row key={x.id}>
                        <Table.Cell>{moment(x.eventtime).format("DD.MM.YY HH:mm")}</Table.Cell>
                        <Table.Cell>{x.manufacturer}</Table.Cell>
                        <Table.Cell>{x.regnumber}</Table.Cell>
                        <Table.Cell>{x.company}</Table.Cell>
                        <Table.Cell>{x.costgroup}</Table.Cell>
                        <Table.Cell>{x.costtype}</Table.Cell>
                        <Table.Cell>{x.total} {x.currency}</Table.Cell>
                        <Table.Cell collapsing>
                            <Button animated compact color="red" onClick={handleDeleteClick} id={x.id} 
                                info={`${moment(x.eventtime).format("DD.MM.YY HH:mm")} : ${x.company} - ${x.total} ${x.currency}`}>
                                <Button.Content visible>{t('data.revenues.table_button_delete')}</Button.Content>
                                <Button.Content hidden>
                                    <Icon name='trash alternate' />
                                </Button.Content>
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    )
}

function RevenuesTable(props){
    const { t } = useTranslation();
    const handleDeleteClick = (e,{id,info}) => { props.onDelete(id,
        `${t('data.revenues.modal_delete_confirm')} ${info} `)}
    return(
        <Table compact celled padded striped singleLine>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{t('data.revenues.table_header_timestamp')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('data.revenues.table_header_manufacturer')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('data.revenues.table_header_regnumber')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('data.revenues.table_header_company')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('data.revenues.table_header_revenuegroup')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('data.revenues.table_header_revenuetype')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('data.revenues.table_header_total')}</Table.HeaderCell>
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {props.revenues.map(x=>{
                    return(
                    <Table.Row key={x.id}>
                        <Table.Cell>{moment(x.eventtime).format("DD.MM.YY HH:mm")}</Table.Cell>
                        <Table.Cell>{x.manufacturer}</Table.Cell>
                        <Table.Cell>{x.regnumber}</Table.Cell>
                        <Table.Cell>{x.company}</Table.Cell>
                        <Table.Cell>{x.revenuegroup}</Table.Cell>
                        <Table.Cell>{x.revenuetype}</Table.Cell>
                        <Table.Cell>{x.total} {x.currency}</Table.Cell>
                        <Table.Cell collapsing>
                            <Button compact animated color="red" onClick={handleDeleteClick} id={x.id}
                            info={`${moment(x.eventtime).format("DD.MM.YY HH:mm")} : ${x.company} - ${x.total} ${x.currency}`}>
                                <Button.Content visible>{t('data.revenues.table_button_delete')}</Button.Content>
                                <Button.Content hidden>
                                    <Icon name='trash alternate' />
                                </Button.Content>
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    )
}

//https://github.com/diogofcunha/react-virtualized-tree/
function Data(props) {
    const [activeItem,setActiveItem]=useState("costs");
    const { t } = useTranslation();
    useEffect(() => {   
        const gCosts = async () => {
            props.getCosts();
        }
        gCosts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
     },[]);
    const handleTabClick = (e,{ k }) => {
        setActiveItem(k);
        if(k==="costs")props.getCosts();
        if(k==="revenues")props.getRevenues();
    }
    let DataTable = <div></div>
    switch(activeItem){
        case "costs": DataTable=<CostsTable costs={props.costs} onDelete={(id,message)=>{props.deleteCost(id,message)}}></CostsTable>
            break;
        case "revenues": DataTable=<RevenuesTable revenues={props.revenues} onDelete={(id,message)=>{props.deleteRevenue(id,message)}}></RevenuesTable>
            break;
        default:
            break;
    }
    return(
        <div className='dataPage'>
            <Menu attached='top' tabular>
                <Menu.Item
                    name={t("data.costs.tab_name")}
                    k="costs"
                    active={activeItem === 'costs'}
                    onClick={handleTabClick}
                />
                <Menu.Item
                    name={t("data.revenues.tab_name")}
                    k="revenues"
                    active={activeItem === 'revenues'}
                    onClick={handleTabClick}
                />
            </Menu>
            {DataTable}
    </div>
    )
}

const mapStateToProps = (state) => ({
    costs:state.data.latestcosts,
    revenues:state.data.latestrevenues
});
const mapDispatchToProps = (dispatch) => ({
    getCosts : () => dispatch(fetchLatestCosts()),
    getRevenues : () => dispatch(fetchLatestRevenues()),
    deleteCost : (id,message)=>dispatch(modalConfirm({
        message:message,
        func:()=>dispatch(removeCost(id)),
    })),
    deleteRevenue : (id,message)=>dispatch(modalConfirm({
        message:message,
        func:()=>dispatch(removeRevenue(id)),
    }))
});
export default connect(
    mapStateToProps,mapDispatchToProps
)(Data)