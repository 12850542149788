import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { DateInput } from "semantic-ui-calendar-react";
import { useHistory } from "react-router-dom";
import { Table } from "semantic-ui-react";

//Import Styles
import "./ViewServices.scss";
import ServiceInfo from "components/ServiceInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteViewServiceByIdFunc,
  getViewService,
  getViewServicesData,
} from "actions/serviceActions";
import { dateOnly, dateStr } from "utils/utils";

/*
Truck as who did (truckshop name), date, and what has been done (oil change, filters change, 
brake pads change, air bags change etc.)
*/
const ViewServices = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data } = props.location.state;
  const dispatch = useDispatch();

  const viewService = useSelector((state) => {
    return state.service.viewServices;
  });
  useEffect(() => {
    dispatch(getViewServicesData(data.VIN));
  }, []);

  const [formData, setFormData] = useState({
    maintainedBy: "",
    eventtime: "",
  });

  const onBack = () => {
    history.push("/serviceview", {data: data});
  };

  const onDelete = (id) => {
    if (window.confirm(t("service.modal_delete_confirm"))) {
      console.log("CONFIRMED");
      dispatch(deleteViewServiceByIdFunc(id));
    }
  };

  return (
    <div className="inputservice" style={{ marginBottom: 64 }}>
      <h2>{t("viewservices.title")}</h2>
      <ServiceInfo regNumber={data.reg_number} serviceID={data.reg_number} />
      {/* <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("addservice.maintained_by")}</label>
            <input />
          </Form.Field>
          <Form.Field>
            <label>{t("addservice.date_of_service")}</label>
          </Form.Field>
        </Form.Group>
      </Form> */}

      <Table
        celled
        compact
        padded
        striped
        singleLine
        style={{ marginBottom: 24 }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("viewservices.date")}</Table.HeaderCell>
            <Table.HeaderCell>{t("viewservices.km")}</Table.HeaderCell>
            <Table.HeaderCell>
              {t("viewservices.service_type")}
            </Table.HeaderCell>
            <Table.HeaderCell>{t("viewservices.axel_type")}</Table.HeaderCell>
            <Table.HeaderCell>{t("viewservices.note")}</Table.HeaderCell>
            <Table.HeaderCell>
              {t("data.costs.table_button_delete")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {viewService &&
            viewService.length > 0 &&
            viewService?.map((x, key) => {
              return (
                <Table.Row key={key}>
                  <Table.Cell>{dateOnly(x.date)}</Table.Cell>
                  <Table.Cell>{x.km}</Table.Cell>
                  <Table.Cell>{x.service_type}</Table.Cell>
                  <Table.Cell>{x.axel_type}</Table.Cell>
                  <Table.Cell>{x.note}</Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      compact
                      color="red"
                      onClick={() => {
                        onDelete(x.id);
                      }}
                      id={x.id}
                    >
                      <Button.Content visible>
                        <Icon name="trash alternate" />
                      </Button.Content>
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      <Button type="cancel" color="red" size="large" onClick={onBack}>
        {t("viewservices.btn_back")}
      </Button>
    </div>
  );
};

export default ViewServices;
