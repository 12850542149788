import React from 'react';
import { connect } from 'react-redux';
import {Header,Icon} from 'semantic-ui-react'

function CSVCheckResult(props){

    let statusIcon = ''
    let statusColor=''
    switch(props.result.type.toLowerCase()){
        case "info":
            statusIcon = 'info';
            statusColor = 'blue';
          break;
        case "success":
            statusIcon = 'checkmark';
            statusColor = 'green';
          break;
        case "warning":
            statusIcon = 'warning';
            statusColor = 'orange';
          break;
        default:
            statusIcon = 'ban';
            statusColor = 'red';
          break;
      }

    return (
        <Header as='h2'>
            <Icon name={statusIcon} color={statusColor} />
            <Header.Content>
                {props.result.message}
            <Header.Subheader>{props.result.details}</Header.Subheader>
            </Header.Content>
        </Header>
    )
}


const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
});
  
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CSVCheckResult)