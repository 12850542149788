import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Card, Grid, Button, Image, List, Label } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

//Import components
import MaintenanceHistory from "components/MaintenanceHistory";
//Import Styles
import "./ServiceView.scss";
//Import utils
import { dateStr, statusStr, kmData, dateOnly } from "utils/utils";
import reactotron from "ReactotronConfig";
import AxelsView from "components/AxelsView";
import {
  fetchVehiclesServiceData,
  fetchVehiclesServiceHistory,
} from "actions/serviceActions";
import { compareNewestService, getLastestService } from "utils/serviceUtils";

const ServiceView = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  let { data } = props.location.state;
  const [serviceData, setServiceData] = useState({});
  const calculateNextInspection = (data, type = "yearly") => {
    let lastInspection = new Date(
      type === "yearly"
        ? data?.inspectionYearly_lastserviceTime
        : data?.inspectionPlotter_lastserviceTime
    );
    let nextInspection = new Date(
      lastInspection.setFullYear(lastInspection.getFullYear() + 1)
    );
    return nextInspection;
  };
  let getNextYearly = useMemo(() => calculateNextInspection(serviceData), [serviceData]);
  let getNextPlotter = useMemo(
    () => calculateNextInspection(serviceData, "plotter"),
    [serviceData]
  );

  const checkInspectionDate = (inspectionDate) => new Date (inspectionDate).getFullYear() !== 1900

  const serviceVehicles = useSelector((state) => {
    return state.service.servicevehicles;
  });
  const servicehistory = useSelector((state) => {
    return state.service.servicehistory;
  });

  useEffect(() => {
    dispatch(fetchVehiclesServiceData());
    dispatch(fetchVehiclesServiceHistory(data.VIN));
  }, []);

  useEffect(() => {
    setServiceData(data);
  }, []);

  useEffect(() => {
    let index = serviceVehicles.findIndex((item) => item.id === data.id);
    console.log("DATA: ", serviceVehicles[index]);
    setServiceData(serviceVehicles[index]);
    // data = serviceVehicles[index]
    // history.replace({...history.location, state: serviceVehicles})
  }, [serviceVehicles]);

  reactotron.logImportant(data);

  // useEffect(() => {
  //   console.log("SERVICE DATA: ", serviceData);
  // }, [serviceData]);

  reactotron.logImportant(data);

  const Data = (data) => {
    return data != null ? data : "?";
  };

  const onAddService = () => {
    history.push("/addservice", { data });
  };

  const onEditVehicle = () => {
    history.push("/editvehicle", { data });
  };

  const onViewServices = () => {
    history.push("/viewservices", { data });
  };

  const checkStatus = (CHK, nextService) => {
    if (CHK < 0) {
      return t("axelstatus.overdue");
    } else {
      if (CHK > nextService) return t("axelstatus.ok");
      if (CHK < nextService) return t("axelstatus.soon");
    }

    return t("axelstatus.notset");
  };

  return (
    <>
      {serviceData && servicehistory && (
        <Grid style={{ margin: 10 }} columns={2}>
          <Grid.Row>
            <Grid.Column width="6">
              <Card fluid>
                <Card.Content>
                  <Card.Header>{t("serviceview.title")}</Card.Header>
                </Card.Content>
                <Card.Content
                  style={{
                    maxHeight: 600,
                    minHeight: 600,
                    overflowY: "scroll",
                  }}
                >
                  <Grid columns={3}>
                    {/* registration number */}
                    <Grid.Row>
                      <Grid.Column className="label">
                        {t("serviceview.reg_number")}
                      </Grid.Column>
                      <Grid.Column className="label">
                        {Data(serviceData.reg_number)}
                      </Grid.Column>
                    </Grid.Row>

                    {/* vehicle id */}
                    <Grid.Row>
                      <Grid.Column className="label">
                        {t("serviceview.vehicle_id")}
                      </Grid.Column>
                      <Grid.Column className="label">
                        {Data(serviceData.reg_number)}
                      </Grid.Column>
                    </Grid.Row>

                    {/* make */}
                    <Grid.Row>
                      <Grid.Column className="label">
                        {t("serviceview.make")}
                      </Grid.Column>
                      <Grid.Column className="label">
                        {Data(serviceData.brand)}
                      </Grid.Column>
                    </Grid.Row>

                    {/* model */}
                    <Grid.Row>
                      <Grid.Column className="label">
                        {t("serviceview.model")}
                      </Grid.Column>
                      <Grid.Column className="label">
                        {Data(serviceData.model)}
                      </Grid.Column>
                    </Grid.Row>

                    {/* year */}
                    <Grid.Row>
                      <Grid.Column className="label">
                        {t("serviceview.year")}
                      </Grid.Column>
                      <Grid.Column className="label">
                        {Data(serviceData.year)}
                      </Grid.Column>
                    </Grid.Row>

                    {/* total km */}
                    <Grid.Row>
                      <Grid.Column className="label">
                        {t("serviceview.total_mileage")}
                      </Grid.Column>
                      <Grid.Column className="label">
                        {kmData(serviceData.total_distance)}
                      </Grid.Column>
                      <Grid.Column className="label">
                        {dateOnly(serviceData.eventTime)}
                      </Grid.Column>
                    </Grid.Row>

                    {/* last service */}
                    <Grid.Row>
                      <Grid.Column className="label">
                        {t("serviceview.last_service")}
                      </Grid.Column>
                      <Grid.Column className="label">
                        {/* {getLastestService(servicehistory)[0]?.km} */}
                        {compareNewestService(serviceData)}
                      </Grid.Column>
                      <Grid.Column className="label">
                        {dateOnly(compareNewestService(serviceData, "date"))}
                      </Grid.Column>
                    </Grid.Row>

                    {/* next service */}
                    <Grid.Row>
                      <Grid.Column className="label">
                        {t("serviceview.next_service")}
                      </Grid.Column>
                      <Grid.Column className="label">
                        {compareNewestService(serviceData) +
                          serviceData.serviceInterval}
                      </Grid.Column>
                      {/* <Grid.Column className="label">
                      {dateStr(data.nextserviceTime)}
                    </Grid.Column> */}
                    </Grid.Row>

                    {/* next yearly inspection */}
                    <Grid.Row>
                      <Grid.Column className="label">
                        {t("serviceview.next_yearly_inspection")}
                      </Grid.Column>
                      <Grid.Column className="label">
                        {checkInspectionDate(serviceData?.inspectionYearly_lastserviceTime) ? dateOnly(getNextYearly) : ""}
                      </Grid.Column>
                    </Grid.Row>

                    {/* next plotter inspection */}
                    <Grid.Row>
                      <Grid.Column className="label">
                        {t("serviceview.next_plotter_inspection")}
                      </Grid.Column>
                      <Grid.Column className="label">
                        {checkInspectionDate(serviceData?.inspectionPlotter_lastserviceTime) ? dateOnly(getNextPlotter) : ""}
                      </Grid.Column>
                    </Grid.Row>

                    {/* status */}
                    <Grid.Row>
                      <Grid.Column className="label">
                        {t("serviceview.status")}
                      </Grid.Column>
                      <Grid.Column className="label">
                        {checkStatus(
                          compareNewestService(serviceData) +
                            serviceData.serviceInterval -
                            serviceData.total_distance / 1000,
                          serviceData.nextservicealert
                        )}
                      </Grid.Column>
                    </Grid.Row>

                    {/* maintained by */}
                    <Grid.Row>
                      <Grid.Column className="label">
                        {t("serviceview.maintained_by")}
                      </Grid.Column>
                      <Grid.Column className="label">
                        {serviceData.maintainedBy === "Not set"
                          ? t("axelstatus.notset")
                          : serviceData.maintainedBy}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column width="10">
              <Card fluid>
                <Card.Content>
                  <Card.Header>
                    {t("serviceview.maintenance_history")}
                  </Card.Header>
                </Card.Content>
                <Card.Content
                  style={{
                    maxHeight: 600,
                    minHeight: 600,
                    overflowY: "scroll",
                  }}
                >
                  <MaintenanceHistory vin={data.VIN} />
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>

          <AxelsView id={data.id} data={data} />

          <Grid.Row className="buttonrow">
            <Button className="large blue" onClick={onViewServices}>
              {t("serviceview.btn_view_services")}
            </Button>
            <Button className="large green" onClick={onAddService}>
              {t("serviceview.btn_add_service")}
            </Button>
            <Button className="large yellow" onClick={onEditVehicle}>
              {t("serviceview.btn_edit_vehicle")}
            </Button>
          </Grid.Row>
        </Grid>
      )}
    </>
  );
};

const AxelHeader = ({ title, numSpaceColumn }) => {
  return (
    <>
      <Grid.Column>
        <Card.Header className="label">{title}</Card.Header>
      </Grid.Column>
      {Array(numSpaceColumn)
        .fill(1)
        .map((value) => (
          <Grid.Column></Grid.Column>
        ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  servicevehicles: state.service.servicevehicles,
});
const mapDispatchToProps = (dispatch) => ({
  //getServiceData: () => dispatch(fetchVehiclesServiceData()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ServiceView);
