import produce from "immer";
import moment from "moment-timezone";

//Import constants
import {
  STATUS_SERVICE_OK,
  STATUS_SERVICE_APPROACHING,
  STATUS_SERVICE_OVERDUE,
} from "../definitions/constants";

// This needs to be the last import
var _ = require("lodash");

function arrayToTree(inputArray, parentName, childrenName) {
  var clonedArray = cloneObject(inputArray);
  if (!clonedArray || clonedArray.length === 0) return [];

  var nodes = {};
  var tree = clonedArray.filter(function (obj) {
    var id = obj[childrenName],
      parentId = obj[parentName];

    nodes[id] = _.defaults(obj, nodes[id], { children: [] });
    parentId &&
      (nodes[parentId] = nodes[parentId] || { children: [] })["children"].push(
        obj
      );

    return !parentId;
  });
  return tree;
}

function cloneObject(inputObject) {
  try {
    return _.cloneDeep(inputObject);
  } catch (Error) {
    return inputObject;
  }
}

function generateCheckBoxTreeArray(
  inputArray,
  selectedEntity,
  valueKey,
  labelKey
) {
  if (!inputArray || inputArray.length <= 0) return [];
  return produce(inputArray, (draft) => {
    draft.forEach((x) => {
      x.value = x[valueKey];
      x.label = x[labelKey];
      x.showCheckbox = false;
      x.className =
        x[valueKey] === selectedEntity[valueKey] ? "isSelected" : "";
    });
  });
}

/**
 * Returns an array containing all the parents of an element
 * @param {array} inputArray Array to search
 * @param {string} key Key of item you want to find the parents for
 * @param {string} parentName Name of the property holding the parent link
 * @param {string} childrenName Name of the property holding the children link
 */
function findParents(inputArray, key, parentName, childrenName) {
  let parentList = [];
  let foundParent = true;
  while (foundParent) {
    let theParent = findParent(inputArray, key, childrenName);
    if (theParent) {
      parentList.push(theParent);
      key = theParent[parentName];
    } else {
      foundParent = false;
    }
  }
  return parentList;
}

/**
 * Returns an element's parent
 * @param {array} inputArray Array to search
 * @param {string} key Key of item you want to find the parent for
 * @param {string} childrenName Name of the property holding the children link
 */
function findParent(inputArray, key, childrenName) {
  let clonedArray = cloneObject(inputArray);
  if (!clonedArray.length) return null;
  return clonedArray.find((x) => x[childrenName] === key);
}

//returns the object with only specified properties
function cleanObject(o, properties) {
  return Object.keys(o).reduce((acc, key) => {
    if (properties.indexOf(key) !== -1) {
      return { ...acc, [key]: o[key] };
    }
    return acc;
  }, {});
}

/**
 * Returns time in seconds and color
 * @param {datetime} eventtime timestamp of the last event
 */
function getVehicleLastContact(eventtime) {
  let lastContactSeconds = moment().diff(moment(eventtime), "seconds");
  let statusColor = "green";
  //bigger than 1 day
  if (lastContactSeconds > 24 * 60 * 60) {
    statusColor = "red";
  } else {
    //bigger than 1 hour
    if (lastContactSeconds >= 1 * 60 * 60) {
      statusColor = "orange";
    }
  }
  return { seconds: lastContactSeconds, color: statusColor };
}

/**
 * @description Checks if object is a date object
 * @param {*} input
 * @returns true if date object, false otherwise
 */
const isDate = (input) => {
  if (Object.prototype.toString.call(input) === "[object Date]") return true;
  return false;
};

/**
 * @description Returns a date string in local format
 * @param {*} date - date object
 * @returns date as a string, "Not set" if date is null
 */
const dateStr = (date) => {
  if (!date) return "Not set";
  if (!isDate(date)) {
    date = new Date(Date.parse(date));
  }
  if (date < new Date(2000, 1, 1)) return "?";
  return date.toLocaleString();
};

const dateOnly = (date) => {
  let d = new Date(date);
  let day = d.getDate().toString();
  let month = (d.getMonth() + 1).toString();
  let year = d.getFullYear();
  if (day.length < 2) {
    day = "0" + day;
  }
  if (month.length < 2) {
    month = "0" + month;
  }
  return [day, month, year].join("/");
};

const timeOnly = (date) => {
  let d = new Date(date);
  let hour = d.getHours().toString();
  let minute = d.getMinutes().toString();
  let second = d.getSeconds().toString();
  if (hour.length < 2) {
    hour = "0" + hour;
  }
  if (minute.length < 2) {
    minute = "0" + minute;
  }
  if (second.length < 2) {
    second = "0" + second;
  }
  return [hour, minute, second].join(":");
};

const formatDateTime = (date) => {
  return [dateOnly(date), timeOnly(date)].join(", ");
};

/**
 * Returns a localized status string
 * @param {*} t - localization object
 * @param {*} status - status code (int)
 * @returns a string
 */
const statusStr = (t, status) => {
  switch (status) {
    case STATUS_SERVICE_OK:
      return t("serviceview.status_ok");
    case STATUS_SERVICE_APPROACHING:
      return t("serviceview.status_approaching");
    case STATUS_SERVICE_OVERDUE:
      return t("serviceview.status_due");
    default:
      return "???";
  }
};

/**
 * @description Converts mileage data (meters) to KN
 * @param {*} data
 * @returns data in KM rounded to 0 decimals
 */
const kmData = (data, round = 0) => {
  return data != null && !isNaN(data) ? (data / 1000).toFixed(round) : "?";
};

export {
  arrayToTree,
  generateCheckBoxTreeArray,
  findParents,
  cleanObject,
  getVehicleLastContact,
  isDate,
  dateStr,
  statusStr,
  kmData,
  dateOnly,
  timeOnly,
  formatDateTime,
};
