import i18n from "i18n";
//Import Actions
import { loading, modalError, addToast } from "./rootActions";

//Import API
import {
  getLatestCosts,
  getLatestRevenues,
  deleteCost,
  deleteRevenue,
} from "api/backendAPI";

export const GET_LATEST_COSTS = "GET_LATEST_COSTS";
export const GET_LATEST_REVENUES = "GET_LATEST_REVENUES";
export const REMOVE_COST_SUCCESS = "REMOVE_COST_SUCCESS";
export const REMOVE_REVENUE_SUCCESS = "REMOVE_REVENUE_SUCCESS";
export const REMOVE_COST = "REMOVE_COST";
export const REMOVE_REVENUE = "REMOVE_REVENUE";

function fetchLatestCosts() {
  return (dispatch) => {
    dispatch(loading(true));
    return getLatestCosts()
      .then((vehicles) => {
        dispatch(loading(false));
        dispatch(fetchLatestCostsSuccess(vehicles));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function fetchLatestCostsSuccess(latestcosts) {
  return {
    type: GET_LATEST_COSTS,
    latestcosts,
  };
}

function removeCost(id) {
  return (dispatch) => {
    dispatch(loading(true));
    return deleteCost(id)
      .then((x) => {
        dispatch(loading(false));
        dispatch(removeCostSuccess(id));
        dispatch(
          addToast({
            type: "success",
            message: i18n.t("data.costs.toast_cost_deleted"),
          })
        );
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function removeCostSuccess(cost_id) {
  return {
    type: REMOVE_COST_SUCCESS,
    cost_id,
  };
}

function fetchLatestRevenues() {
  return (dispatch) => {
    dispatch(loading(true));
    return getLatestRevenues()
      .then((latestrevenues) => {
        dispatch(loading(false));
        dispatch(fetchLatestRevenuesSuccess(latestrevenues));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function fetchLatestRevenuesSuccess(latestrevenues) {
  return {
    type: GET_LATEST_REVENUES,
    latestrevenues,
  };
}

function removeRevenue(id) {
  return (dispatch) => {
    dispatch(loading(true));
    return deleteRevenue(id)
      .then((x) => {
        dispatch(loading(false));
        dispatch(removeRevenueSuccess(id));
        dispatch(
          addToast({
            type: "success",
            message: i18n.t("data.revenues.toast_revenue_deleted"),
          })
        );
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function removeRevenueSuccess(revenue_id) {
  return {
    type: REMOVE_REVENUE_SUCCESS,
    revenue_id,
  };
}

export { fetchLatestCosts, fetchLatestRevenues, removeCost, removeRevenue };
