//https://auth0.com/docs/quickstart/spa/react/02-calling-an-api#call-the-api
import axios from "axios";
import reactotron from "ReactotronConfig";
//import basic from 'basic-authorization-header';

import { sec } from "utils/security";

const backendAPI = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_ENDPOINT,
  // baseURL: 'http://localhost:3001',
  timeout: 30000,
});

//Interceptors
//Inject JWT Access Token to each request
backendAPI.interceptors.request.use(
  async (config) => {
    const token = await sec.getAccessTokenSilently()();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
//return data from each response and reject in case of error which will trigger ModalError
backendAPI.interceptors.response.use(
  (response) => {
    reactotron.logImportant('backendAPI', response.config.url, response)
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

async function checkCSV(fileData) {
  let formData = new FormData();
  formData.append(fileData.fileName, fileData.content);
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  return backendAPI.post("/csv/check", formData, { headers });
}

async function validateCSV(fileName) {
  return backendAPI.get("/csv/validate/" + fileName);
}

async function getVehicles() {
  return backendAPI.get("/vehicles");
}

async function getCompanies() {
  return backendAPI.get("/manualcostcompanies");
}

async function getLocations() {
  return backendAPI.get("/manualcostlocations");
}

async function getCostTypes() {
  return backendAPI.get("/manualcosttypes");
}

async function addCost(costdata) {
  return backendAPI.post("/cost", costdata);
}

async function getUnlinkedRevenues() {
  return backendAPI.get("/unlinkedrevenues");
}

async function linkVehicleToRevenue(linkdata) {
  return backendAPI.post(
    `/vehicle/${linkdata.vehicle_id}/link`,
    linkdata.revenue_id
  );
}

async function getRevenueTypes() {
  return backendAPI.get("/manualrevenuetypes");
}

async function addRevenue(revenuedata) {
  return backendAPI.post("/revenue", revenuedata);
}

async function getLatestCosts() {
  return backendAPI.get("/latestmanualcosts");
}

async function deleteCost(id) {
  return backendAPI.delete("/cost/" + id);
}

async function getLatestRevenues() {
  return backendAPI.get("/latestmanualrevenues");
}

async function deleteRevenue(id) {
  return backendAPI.delete("/revenue/" + id);
}

async function getCostGroups() {
  return backendAPI.get("/costgroups");
}

async function getRevenueGroups() {
  return backendAPI.get("/revenuegroups");
}

async function getUngroupedCostTypes() {
  return backendAPI.get("/costtypes/ungrouped");
}
async function getUngroupedRevenueTypes() {
  return backendAPI.get("/revenuetypes/ungrouped");
}

async function linkCosttypesToGroup(linkdata) {
  return backendAPI.post(
    `/costtypes/${linkdata.group_id}/link`,
    linkdata.costtypes
  );
}

async function linkRevenuetypesToGroup(linkdata) {
  return backendAPI.post(
    `/revenuetypes/${linkdata.group_id}/link`,
    linkdata.revenuetypes
  );
}

// Service fleet APIs
async function getVehicleServiceData() {
  return backendAPI.get("/servicevehicles");
}

async function updateVehicleServiceData(servicedata) {
  return backendAPI.put("/servicevehicles", servicedata);
}

async function getVehicleServiceHistory(vin) {
  return backendAPI.get(`/serviceevent/${vin}`);
}

async function getVehicleServiceAxels(id) {
  return backendAPI.get(`/serviceaxels/${id}`);
}

async function updateVehicleService(id, data) {
  return backendAPI.put(`/servicevehicles/${id}`, data)
}

async function addService(id, data) {
  return backendAPI.post(`/serviceevent/${id}`, data)
}

async function getViewServices(id) {
  return backendAPI.get(`/serviceevent/${id}`)
}

async function deleteViewServiceById(id) {
  return backendAPI.delete(`/serviceevent/${id}`)
}

export {
  checkCSV,
  validateCSV,
  getVehicles,
  getCompanies,
  getLocations,
  getCostTypes,
  addCost,
  getUnlinkedRevenues,
  linkVehicleToRevenue,
  getRevenueTypes,
  addRevenue,
  getLatestCosts,
  getLatestRevenues,
  deleteCost,
  deleteRevenue,
  getCostGroups,
  getRevenueGroups,
  getUngroupedCostTypes,
  getUngroupedRevenueTypes,
  linkCosttypesToGroup,
  linkRevenuetypesToGroup,
  getVehicleServiceData,
  updateVehicleServiceData,
  getVehicleServiceHistory,
  getVehicleServiceAxels,
  updateVehicleService, addService,
  getViewServices,
  deleteViewServiceById
};
