import React from "react";
import { Icon } from "semantic-ui-react";

export const getStatusIcon = (chk, nextServiceAlert, type = "icon", t) => {
  // <Icon name="trash alternate" />
  if (chk < 0) {
    return { getIcon: () => getIcon("overdue", type, t), status: "overdue" };
    // return <img src={require('../images/stop.png')} style={{width: 24, height: 24}}/>
  } else {
    if ((chk > nextServiceAlert, type)) {
      return { getIcon: () => getIcon("ok"), status: "ok" };
    }
    if ((chk < nextServiceAlert, type)) {
      return { getIcon: () => getIcon("soon"), status: "soon" };
    }
  }
  return { getIcon: () => getIcon(), status: "" };
};

export const checkServiceByKm = (chk, nextServiceAlert) => {
  // <Icon name="trash alternate" />
  if (chk < 0) {
    return "overdue";
    // return <img src={require('../images/stop.png')} style={{width: 24, height: 24}}/>
  } else {
    if (chk > nextServiceAlert) {
      return "ok";
    }
    if (chk < nextServiceAlert) {
      return "soon";
    }
  }
};

export const getIcon = (status, type = "icon", t) => {
  switch (status) {
    case "overdue":
      return (
        <div style={{ flexDirection: "row", display: "flex" }}>
          {type === "text" && (
            <p style={{ marginRight: 8 }}>{t("axelstatus.overdue")}</p>
          )}
          <Icon name="ban" color="red" size="large" />
        </div>
      );
    case "soon":
      return (
        <div style={{ flexDirection: "row", display: "flex" }}>
          {type === "text" && (
            <p style={{ marginRight: 8 }}>
              {t("axelstatus.soon") || t("axelstatus.notset")}
            </p>
          )}
          <Icon name="warning sign" color="orange" size="large" />
        </div>
      );
    case "ok":
      return (
        <div style={{ flexDirection: "row", display: "flex" }}>
          {type === "text" && (
            <p style={{ marginRight: 8 }}>
              {t("axelstatus.ok") || t("axelstatus.notset")}
            </p>
          )}
          <Icon name="check circle" color="green" size="large" />
        </div>
      );
    default:
      return <></>;
  }
};

export const checkServiceByTime = (lastServiceTime, nextServiceTime) => {
  let lastTime = new Date(lastServiceTime).getTime();
  let nextTime = new Date(nextServiceTime).getTime();
  // <Icon name="trash alternate" />
  if (lastTime > nextTime) return "ok";
  return "overdue";
};

export const addDays = (date, days) => {
  const d = new Date(date);
  return d.setDate(new Date(d.getDate() + days));
};

export const getStatus = (x) => {
  let status = "";
  let reason = "first defining";
  const serviceCheckingOrder = [
    checkServiceByKm(
      x.serviceA_lastservice + x.serviceInterval - x.total_distance / 1000,
      x.nextservicealert
    ),
    checkServiceByKm(
      x.serviceB_lastservice + x.serviceInterval - x.total_distance / 1000,
      x.nextservicealert
    ),
    checkServiceByTime(
      addDays(x.inspectionPlotter_lastserviceTime, 365),
      Date.now()
    ),
    checkServiceByTime(
      addDays(x.inspectionYearly_lastserviceTime, 365),
      Date.now()
    ),
  ];
  for (let i = 0; i < serviceCheckingOrder.length; i++) {
    status = serviceCheckingOrder[i];
    switch (i) {
      case 0:
        reason = "service A";
        break;
      case 1:
        reason = "service B";
        break;
      case 2:
        reason = "plotter";
        break;
      case 3:
        reason = "annual";
        break;
      default:
        reason = "unknown";
        break;
    }
    if (status === "overdue") {
      break;
    }
  }
  return getIcon(status);
};

export const compareNewestServiceOld = (data, type = "data") => {
  let date1 = new Date(data.serviceA_lastserviceTime).getTime();
  let date2 = new Date(data.serviceB_lastserviceTime).getTime();
  if (type === "data") {
    if (date1 < date2) {
      return data.serviceB_lastservice;
    }
    return data.serviceA_lastservice;
  } else {
    if (date1 < date2) {
      return date2;
    }
    return date1;
  }
};

export const compareNewestService = (data, type = "data") => {
  if (type === "data") {
    if (data.serviceA_lastservice < data.serviceB_lastservice) {
      return data.serviceB_lastservice;
    }
    return data.serviceA_lastservice;
  } else {
    let date1 = new Date(data.serviceA_lastserviceTime).getTime();
    let date2 = new Date(data.serviceB_lastserviceTime).getTime();
    if (date1 < date2) {
      return date2;
    }
    return date1;
  }
};

export const getLastestService = (serviceHistory) => {
  return serviceHistory
    .filter(
      (a) => a.service_type === "Service A" || a.service_type === "Service B"
    )
    .sort((a, b) => {
      let date1 = new Date(a.date).getTime();
      let date2 = new Date(b.date).getTime();
      if (date1 === date2) {
        return parseInt(b.id) - parseInt(a.id);
      }
      return date2 - date1;
    });
};
