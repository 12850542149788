import React from "react";
import { connect } from "react-redux";
import { Grid, Card, Header, Statistic, List, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Import styles
import "./VehicleCard.scss";

//Import logos
import unknownlogo from "images/brands/unknown.png";
import volvologo from "images/brands/volvo.png";
import mercedeslogo from "images/brands/mercedes.png";

//Import utils
import { getVehicleLastContact } from "utils/utils";

const VehicleCard = (props) => {
  const { t } = useTranslation();
  let { color } = getVehicleLastContact(props.vehicle.timestamp);
  let carLogo = unknownlogo;

  switch (props.vehicle.brand.toLowerCase()) {
    case "volvo":
      carLogo = volvologo;
      break;
    case "mercedes":
      carLogo = mercedeslogo;
      break;
    default:
      break;
  }

  const serviceClass = (status) => {
    switch (status) {
      case 1:
        return "servicenear";
      case 2:
        return "servicedue";
      default:
        return "totaldistance";
    }
  };

  return (
    <Card fluid color={color} className="vehicle">
      <Card.Content>
        <Grid columns={5} divided verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={7} className="vehicleName">
              <Header>
                {props.vehicle.reg_number}
                <Header.Subheader className="serial">
                  {props.vehicle.VIN}
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column width={9} textAlign="center" className="stats">
              {/*<Statistic className="brand">
                  <Statistic.Value>{props.vehicle.brand}</Statistic.Value>
                  <Statistic.Label>{t('dashboard.vehicle.brand')}</Statistic.Label>
                  </Statistic>*/}
              <Image src={carLogo} size="tiny" />
              <Statistic className="model">
                <Statistic.Value>{props.vehicle.model}</Statistic.Value>
                <Statistic.Label>
                  {t("dashboard.vehicle.model")}
                </Statistic.Label>
              </Statistic>
              <Statistic className="year">
                <Statistic.Value>{props.vehicle.year}</Statistic.Value>
                <Statistic.Label>{t("dashboard.vehicle.year")}</Statistic.Label>
              </Statistic>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
      <Card.Content>
        <Grid columns={2} divided verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={8} className="totaldistance">
              <List>
                <List.Item>
                  <List.Icon name="road" size="large" verticalAlign="middle" />
                  <List.Content>
                    <List.Header>
                      {Math.round(props.vehicle.total_distance / 1000)}{" "}
                      {t("common.unit.kilometer")}
                    </List.Header>
                    <List.Description>
                      {t("dashboard.vehicle.total_distance")}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column
              width={8}
              className={serviceClass(props.vehicle.status)}
            >
              <List>
                <List.Item>
                  <List.Icon name="road" size="large" verticalAlign="middle" />
                  <List.Content className={serviceClass(props.vehicle.status)}>
                    <List.Header>
                      {Math.round(props.vehicle.nextService / 1000)}{" "}
                      {t("common.unit.kilometer")}
                    </List.Header>
                    <List.Description>
                      {t("dashboard.vehicle.next_service")}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleCard);
