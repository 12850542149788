import produce from "immer";

//Import actions
import {
  GET_VEHICLES_SERVICE_DATA,
  UPDATE_VEHICLE_SERVICE_DATA,
  GET_VEHICLES_SERVICE_HISTORY,
  GET_VEHICLES_SERVICE_AXELS,
  GET_VIEW_SERVICE
} from "actions/serviceActions";

const initialState = {
  servicevehicles: [],
  servicehistory: [],
  serviceAxels: {},
  viewServices: []
};

const serviceReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_VEHICLES_SERVICE_DATA:
        draft.servicevehicles = action.servicevehicles;
        break;
      case UPDATE_VEHICLE_SERVICE_DATA:
        // todo: update servicevehicles list or just read it
        break;
      case GET_VEHICLES_SERVICE_HISTORY:
        draft.servicehistory = action.servicehistory;
        break;
      case GET_VEHICLES_SERVICE_AXELS:
        draft.serviceAxels = action.serviceAxels;
        break;
      case GET_VIEW_SERVICE:
        draft.viewServices = action.viewServices
      default:
        break;
    }
  });

export default serviceReducer;
