import Reactotron from 'reactotron-react-js'
import { reactotronRedux } from 'reactotron-redux'
import apisaucePlugin from 'reactotron-apisauce'

const reactotron = Reactotron
    .configure({ name: 'Milisecond Fleet' }) // we can use plugins here -- more on this later
    .use(reactotronRedux())
    .use(apisaucePlugin())
    .connect() // let's connect!

export default reactotron