import i18n from "i18n";
//Import Actions
import { loading, modalError, addToast } from "./rootActions";

//Import API
import {
  getVehicleServiceData,
  updateVehicleServiceData,
  getVehicleServiceHistory,
  getVehicleServiceAxels,
  updateVehicleService,
  addService,
  getViewServices,
  deleteViewServiceById
} from "api/backendAPI";

export const GET_VEHICLES_SERVICE_DATA = "GET_VEHICLES_SERVICE_DATA";
export const GET_VEHICLES_SERVICE_HISTORY = "GET_VEHICLES_SERVICE_HISTORY";
export const GET_VEHICLES_SERVICE_AXELS = "GET_VEHICLES_SERVICE_AXELS";
export const UPDATE_VEHICLE_SERVICE_DATA = "UPDATE_VEHICLE_SERVICE_DATA";
export const ADD_SERVICE_EVENT = "ADD_SERVICE_EVENT";
export const GET_VIEW_SERVICE = "GET_VIEW_SERVICE"


function fetchVehiclesServiceData() {
  return (dispatch) => {
    dispatch(loading(true));
    return getVehicleServiceData()
      .then((servicevehicles) => {
        dispatch(loading(false));
        dispatch(success(servicevehicles));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };

  function success(servicevehicles) {
    return {
      type: GET_VEHICLES_SERVICE_DATA,
      servicevehicles,
    };
  }
}

function fetchVehiclesServiceHistory(vin) {
  return (dispatch) => {
    dispatch(loading(true));
    return getVehicleServiceHistory(vin)
      .then((servicehistory) => {
        dispatch(loading(false));
        dispatch(success(servicehistory));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };

  function success(servicehistory) {
    return {
      type: GET_VEHICLES_SERVICE_HISTORY,
      servicehistory,
    };
  }
}

function fetchVehiclesServiceAxels(id) {
  return (dispatch) => {
    dispatch(loading(true));
    return getVehicleServiceAxels(id)
      .then((serviceAxels) => {
        dispatch(loading(false));
        dispatch(success(serviceAxels));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };

  function success(serviceAxels) {
    return {
      type: GET_VEHICLES_SERVICE_AXELS,
      serviceAxels,
    };
  }
}

function updateServiceData(servicedata) {
  return (dispatch) => {
    dispatch(loading(true));
    return updateVehicleServiceData(servicedata)
      .then((x) => {
        dispatch(loading(false));
        dispatch(success());
        dispatch(
          addToast({
            type: "success",
            message: i18n.t("service.service_data_updated"),
          })
        );
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
  function success() {
    return {
      type: UPDATE_VEHICLE_SERVICE_DATA,
    };
  }
}

function updateVehicleServiceDataById(id, data) {
  return (dispatch) => {
    dispatch(loading(true));
    return updateVehicleService(id, data)
      .then((x) => {
        dispatch(loading(false));
        dispatch(success());
        dispatch(
          addToast({
            type: "success",
            message: i18n.t("service.service_data_updated"),
          })
        );
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
  function success() {
    return {
      type: UPDATE_VEHICLE_SERVICE_DATA,
    };
  }
}

function addServiceEvent(id, data) {
  return (dispatch) => {
    dispatch(loading(true));
    return addService(id, data)
      .then((x) => {
        dispatch(loading(false));
        dispatch(success());
        dispatch(
          addToast({
            type: "success",
            message: i18n.t("service.service_data_updated"),
          })
        );
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
  function success() {
    return {
      type: ADD_SERVICE_EVENT,
    };
  }
}

function getViewServicesData(id) {
  return (dispatch) => {
    dispatch(loading(true));
    return getViewServices(id)
      .then((x) => {
        dispatch(loading(false));
        dispatch(success(x));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
  function success(viewServices) {
    return {
      type: GET_VIEW_SERVICE,
      viewServices
    };
  }
}

function deleteViewServiceByIdFunc(id) {
  return (dispatch) => {
    dispatch(loading(true));
    return deleteViewServiceById(id)
      .then((x) => {
        dispatch(loading(false));
        dispatch(success(x));
        window.location.reload()
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
  function success(viewServices) {
    return {
      type: GET_VIEW_SERVICE,
      viewServices
    };
  }
}

export {
  fetchVehiclesServiceData,
  updateServiceData,
  fetchVehiclesServiceHistory,
  fetchVehiclesServiceAxels,
  updateVehicleServiceDataById,
  addServiceEvent,
  getViewServicesData,
  deleteViewServiceByIdFunc
};
