import React, { useState, useEffect } from "react";
import { Button, Checkbox, Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { DateInput } from "semantic-ui-calendar-react";
import { useHistory } from "react-router-dom";
import {fetchVehiclesServiceData, updateServiceData} from "../actions/serviceActions";

//Import Styles
import "./EditVehicle.scss";
import ServiceInfo from "components/ServiceInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  updateVehicleServiceDataById,
  fetchVehiclesServiceAxels,
} from "../actions/serviceActions";

/*
Truck as who did (truckshop name), date, and what has been done (oil change, filters change, 
brake pads change, air bags change etc.)
*/

const range = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
};

const MAX_AXIS_COUNT = 10;

const AXIS_COUNT_OPTIONS = range(2, 10).map((value, _) => {
  return { key: value, value, text: value.toString() };
});

const createAxelBase = (serviceInterval = 0, lastService = 0) => {
  return {
    lastService,
    lastServiceTime: null,
    serviceInterval,
  };
};

const createNewAxelInfo = () => {
  return {
    tire: false,
    tireInterval: 0,
    brakepad: false,
    brakepadInterval: 0,
    brakedisk: false,
    brakediskInterval: 0,
    airbag: false,
    airbagInterval: 0,
  };
};

const checkTrueFalse = (item) => {
  return item ? true : false;
};

const changeData = (obj, prop1, prop2, value) => {
  let cloneObj = { ...obj };
  cloneObj[prop1][prop2] = parseInt(value);
  return cloneObj;
};

const createNewAxel = (id) => {
  return [
    {
      next_change: {
        id: id,
        tires: {
          last_service: 0,
          last_service_time: new Date(Date.now()).toISOString(),
          service_interval: 0,
        },
        break_disk: {
          last_service: 0,
          last_service_time: new Date(Date.now()).toISOString(),
          service_interval: 0,
        },
        break_pad: {
          last_service: 0,
          last_service_time: new Date(Date.now()).toISOString(),
          service_interval: 0,
        },
        air_bag: {
          last_service: 0,
          last_service_time: new Date(Date.now()).toISOString(),
          service_interval: 0,
        },
      },
    },
    {
      last_change: {
        id: id,
        tires: {
          last_service: 0,
          last_service_time: new Date(Date.now()).toISOString(),
          service_interval: 0,
        },
        break_disk: {
          last_service: 0,
          last_service_time: new Date(Date.now()).toISOString(),
          service_interval: 0,
        },
        break_pad: {
          last_service: 0,
          last_service_time: new Date(Date.now()).toISOString(),
          service_interval: 0,
        },
        air_bag: {
          last_service: 0,
          last_service_time: new Date(Date.now()).toISOString(),
          service_interval: 0,
        },
      },
    },
  ];
};

const AxisConfiguration = ({ data, onAxelChange, id }) => {
  const { t } = useTranslation();

  const [isTireChecked, setIsTireChecked] = useState(
    checkTrueFalse(data?.tires)
  );
  const [isBrakePadChecked, setIsBrakePadChecked] = useState(
    checkTrueFalse(data?.break_pad)
  );
  const [isBrakeDiskChecked, setIsBrakeDiskChecked] = useState(
    checkTrueFalse(data?.break_disk)
  );
  const [isAirBagChecked, setIsAirBagChecked] = useState(
    checkTrueFalse(data?.air_bag)
  );

  return (
    <Form.Group inline>
      <label>{t("editvehicle.axis_configuration")}</label>
      <Form.Checkbox
        label={t("editvehicle.tire")}
        checked={isTireChecked}
        value={isTireChecked}
        onChange={() => {
          setIsTireChecked(!isTireChecked);
        }}
      />
      <Form.Input
        label={t("editvehicle.service_interval")}
        fluid
        value={data?.tires?.service_interval}
        onChange={(event, value) => {
          let cloneObj = changeData(
            data,
            "tires",
            "service_interval",
            value.value
          );
          onAxelChange(id, cloneObj);
        }}
        type="number"
        size="mini"
        className="interval_input"
      />

      <Form.Checkbox
        label={t("editvehicle.brake_pad")}
        checked={isBrakePadChecked}
        value={isBrakePadChecked}
        onChange={() => {
          setIsBrakePadChecked(!isBrakePadChecked);
        }}
      />

      <Form.Input
        label={t("editvehicle.service_interval")}
        fluid
        value={data?.break_pad?.service_interval}
        onChange={(event, value) => {
          let cloneObj = changeData(
            data,
            "break_pad",
            "service_interval",
            value.value
          );
          onAxelChange(id, cloneObj);
        }}
        type="number"
        size="mini"
        className="interval_input"
      />

      <Form.Checkbox
        label={t("editvehicle.brake_disk")}
        checked={isBrakeDiskChecked}
        value={isBrakeDiskChecked}
        onChange={() => {
          setIsBrakeDiskChecked(!isBrakeDiskChecked);
        }}
      />
      <Form.Input
        label={t("editvehicle.service_interval")}
        fluid
        value={data?.break_disk?.service_interval}
        onChange={(event, value) => {
          let cloneObj = changeData(
            data,
            "break_disk",
            "service_interval",
            value.value
          );
          onAxelChange(id, cloneObj);
        }}
        type="number"
        size="mini"
        className="interval_input"
      />

      <Form.Checkbox
        label={t("editvehicle.air_bag")}
        checked={isAirBagChecked}
        value={isAirBagChecked}
        onChange={() => {
          setIsAirBagChecked(!isAirBagChecked);
        }}
      />
      <Form.Input
        label={t("editvehicle.service_interval")}
        fluid
        value={data?.air_bag?.service_interval}
        onChange={(event, value) => {
          let cloneObj = changeData(
            data,
            "air_bag",
            "service_interval",
            value.value
          );
          onAxelChange(id, cloneObj);
        }}
        type="number"
        size="mini"
        className="interval_input"
      />
    </Form.Group>
  );
};

const EditVehicle = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { data } = props.location.state;

  useEffect(() => {
    dispatch(fetchVehiclesServiceAxels(data.id));
    dispatch(fetchVehiclesServiceData());
  }, []);

  // my state
  const [serviceInterval, setServiceInterval] = useState(data.serviceInterval);
  const [nextServiceAlert, setNextServiceAlert] = useState(
    data.nextservicealert
  );
  // const [axel, setAxel] = useState([])
  const axel = useSelector((state) => {
    return state.service.serviceAxels;
  });

  const servicevehiclesData = useSelector((state) => state.service.servicevehicles)
  // need to create new state based on axel to prevent overwrite the core data
  const [axelState, setAxelState] = useState({});

  useEffect(() => {
    const editedData = servicevehiclesData.find((edit) => edit?.id === data.id)
    setServiceInterval(editedData?.serviceInterval)
    setNextServiceAlert(editedData?.nextservicealert)
  }, [servicevehiclesData])

  useEffect(() => {
    setAxelState(axel);
  }, [axel]);
  const [numberOfAxis, setNumberOfAxis] = useState(0);

  useEffect(() => {
    let axelCount = axel ? Object.keys(axel).length : 0;
    setNumberOfAxis(axelCount);
  }, [axel]);

  const onCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (numberOfAxis > MAX_AXIS_COUNT || numberOfAxis < 2) return;
    const axelArray = new Array();
    for (let i = 0; i < numberOfAxis; i++) {
      const axelInfo = createNewAxelInfo();
      axelArray.push({ ...axelInfo, id: i });
    }
  }, [numberOfAxis]);

  const onAxelChange = (id, changedAxel) => {
    let nextState = { ...axelState };
    nextState[id].filter((item) => item.hasOwnProperty("last_change"))[0] =
      changedAxel;
    setAxelState(nextState);
  };

  const onNumberOfAxisChange = (e, x) => {
    let axelStateClone = { ...axel };
    if (x.value > Object.keys(axelStateClone).length) {
      setNumberOfAxis(x.value);
      for (let i = 1; i <= x.value; i++) {
        if (!axelStateClone.hasOwnProperty(`Axel ${i}`)) {
          axelStateClone[`Axel ${i}`] = createNewAxel(0);
        }
      }
    } else {
      let i = Object.keys(axelStateClone).length - x.value;
      while (i > 0) {
        delete axelStateClone[
          Object.keys(axelStateClone)[Object.keys(axelStateClone).length - 1]
        ];
        i--;
      }
    }
    setAxelState(axelStateClone);
    setNumberOfAxis(x.value);
  };

  const onSave = () => {
    let axelStateClone = { ...axelState };
    Object.keys(axelStateClone).map((item) => {
      axelStateClone[item] = axelStateClone[item].filter((item) =>
        item.hasOwnProperty("last_change")
      )[0]["last_change"];
    });
    let savedData = {
      axel: axelStateClone,
      serviceInterval,
      nextservicealert: nextServiceAlert,
    };
    dispatch(updateVehicleServiceDataById(data.id, savedData));
  };

  return (
    <div className="inputservice">
      <h2>{t("editvehicle.title")}</h2>
      <ServiceInfo regNumber={data.reg_number} serviceID={data.reg_number} />
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            fluid
            label={t("editvehicle.service_interval")}
            value={serviceInterval}
            onChange={(event, value) => {
              setServiceInterval(parseInt(value.value));
            }}
            type={"number"}
          />
          <Form.Input
            fluid
            label={t("editvehicle.next_service_alert")}
            value={nextServiceAlert}
            onChange={(event, value) => {
              setNextServiceAlert(parseInt(value.value));
            }}
            type={"number"}
          />
          <Form.Select
            fluid
            label={t("editvehicle.axis_count")}
            value={numberOfAxis}
            options={AXIS_COUNT_OPTIONS}
            onChange={onNumberOfAxisChange}
          />
        </Form.Group>

        {axelState &&
          Object.keys(axelState).map((prop, index) => {
            let nextChange = axelState[prop].filter((item) =>
              item.hasOwnProperty("last_change")
            );
            return (
              <AxisConfiguration
                data={
                  nextChange && nextChange[0] && nextChange[0]["last_change"]
                }
                id={prop}
                key={index}
                onAxelChange={onAxelChange}
              />
            );
          })}

        <Button type="submit" color="green" size="large" onClick={onSave}>
          {t("editvehicle.btn_save")}
        </Button>
        <Button type="cancel" color="red" size="large" onClick={onCancel}>
          {t("editvehicle.btn_cancel")}
        </Button>
      </Form>
    </div>
  );
};

export default EditVehicle;
