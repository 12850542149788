import produce from "immer";

//Import actions
import {
  CHECK_CSV_SUCCESS,
  CONFIRM_CSV_SUCCESS,
  GET_VEHICLES_SUCCESS,
  GET_COMPANIES_SUCCESS,
  GET_LOCATIONS_SUCCESS,
  GET_COSTTYPES_SUCCESS,
  GET_REVENUETYPES_SUCCESS,
  GET_UNLINKED_REVENUES_SUCCESS,
  GET_COSTGROUPS_SUCCESS,
  GET_REVENUEGROUPS_SUCCESS,
  GET_UNGROUPEDCOSTTYPES_SUCCESS,
  GET_UNGROUPEDREVENUETYPES_SUCCESS,
} from "actions/inputActions";

const initialState = {
  csvCheckResult: {},
  vehicles: [],
  locations: [],
  costtypes: [],
  revenuetypes: [],
  companies: [],
  unlinkedrevenues: [],
  costgroups: [],
  revenuegroups: [],
  ungroupedcosttypes: [],
  ungroupedrevenuetypes: [],
};

const inputReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHECK_CSV_SUCCESS:
        draft.csvCheckResult = action.result;
        break;
      case CONFIRM_CSV_SUCCESS:
        draft.csvCheckResult = initialState.csvCheckResult;
        break;
      case GET_VEHICLES_SUCCESS:
        draft.vehicles = action.vehicles;
        break;
      case GET_COMPANIES_SUCCESS:
        draft.companies = action.companies;
        break;
      case GET_LOCATIONS_SUCCESS:
        draft.locations = action.locations;
        break;
      case GET_COSTTYPES_SUCCESS:
        draft.costtypes = action.costtypes;
        break;
      case GET_REVENUETYPES_SUCCESS:
        draft.revenuetypes = action.revenuetypes;
        break;
      case GET_UNLINKED_REVENUES_SUCCESS:
        draft.unlinkedrevenues = action.unlinkedrevenues;
        break;
      case GET_COSTGROUPS_SUCCESS:
        draft.costgroups = action.costgroups;
        break;
      case GET_REVENUEGROUPS_SUCCESS:
        draft.revenuegroups = action.revenuegroups;
        break;
      case GET_UNGROUPEDCOSTTYPES_SUCCESS:
        draft.ungroupedcosttypes = action.ungroupedcosttypes;
        break;
      case GET_UNGROUPEDREVENUETYPES_SUCCESS:
        draft.ungroupedrevenuetypes = action.ungroupedrevenuetypes;
        break;
      default:
        break;
    }
  });

export default inputReducer;
