import React from 'react';
import { connect } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import { Menu, Label, Icon } from 'semantic-ui-react'

import './Navbar.scss';
import NavbarLang from 'components/NavbarLang'
//Actions imports
import {setUserAuth} from 'actions/rootActions';


function NavbarAuth(props){
  
  const { isAuthenticated,loginWithRedirect,logout,user,loading} = useAuth0();
  props.userAuth(user);
  return(
      <Menu.Item position='right' className="noBorderHover navRight">
        <NavbarLang/>
        {!isAuthenticated && !loading &&(
          <Label as='a' size='big' color='blue' onClick={() => loginWithRedirect({})}>
            <Icon name='lock open'/><span className="loginText">Login</span>
          </Label>
        )}
        {isAuthenticated && user && !loading &&(
          <Label as='a' size='big' color='green' onClick={() => logout({})}>
            <Icon name='lock'/><span className="loginText">{user.email}</span>
          </Label>
        )}
      </Menu.Item>
    );
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  userAuth : (user) => dispatch(setUserAuth(user))
})

export default connect(
  mapStateToProps,mapDispatchToProps
)(NavbarAuth);