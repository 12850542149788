import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, Form, Message, List } from "semantic-ui-react";
import InputNumber from "rc-input-number";
import { useTranslation } from "react-i18next";
import { DateTimeInput } from "semantic-ui-calendar-react";
import moment from "moment";

//Import Actions
import {
  fetchLocations,
  fetchCostTypes,
  insertCost,
} from "actions/inputActions";

//Import Components
import ActionButton from "components/ActionButton";

//Import Styles
import "./InputCostForm.scss";

function InputCostForm(props) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    eventtime: "",
    vehicle_id: 0,
    company_id: 0,
    location_id: 0,
    costtype_id: 0,
    currency: "EUR",
    quantity: 1,
    total: 0.0,
    vatrate: 0.0,
    vat: 0.0,
    vattotal: 0.0,
  });
  const [errorList, setErrorList] = useState([]);

  const onDropDownChanged = (e, { value, name }) => {
    setFormData({ ...formData, [name]: value });
  };
  const onDateTimeChanged = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSaveCost = () => {
    let costData = {
      vehicle_id: formData.vehicle_id,
      company_id: formData.company_id,
      location_id: 0,
      costtype_id: formData.costtype_id,
      eventtime: moment(formData.eventtime, "DD.MM.YYYY HH:mm").toJSON(),
      currency: formData.currency,
      quantity: parseFloat(formData.quantity),
      total: parseFloat(formData.total),
      VAT_rate: parseFloat(formData.vatrate),
      VAT: parseFloat(formData.vat),
      VAT_total: parseFloat(formData.vattotal),
    };
    let eList = [];
    //Check the data
    if (!costData.eventtime) {
      eList.push(t("input.costform.errors.invalid_timestamp"));
    }
    /*if (costData.vehicle_id === 0) {
      eList.push(t("input.costform.errors.missing_vehicle"));
    }*/
    if (costData.company_id === 0) {
      eList.push(t("input.costform.errors.missing_company"));
    }
    /*if (costData.location_id === 0) {
      eList.push(t("input.costform.errors.missing_location"));
    }*/
    if (costData.costtype_id === 0) {
      eList.push(t("input.costform.errors.missing_costtype"));
    }
    if (costData.currency === "") {
      eList.push(t("input.costform.errors.missing_currency"));
    }
    if (costData.total <= 0) {
      eList.push(t("input.costform.errors.invalid_total"));
    }
    setErrorList(eList);
    if (eList.length === 0) {
      props.addCost(costData).then((x) => {
        resetForm();
      });
    }
  };

  const onFinancialDataChanged = (value, name) => {
    let { vatvalue, vattotal } = 0;
    switch (name) {
      //automatically calculate vat value and vat total
      case "total":
        vatvalue = value * (formData.vatrate / 100);
        vattotal = vatvalue + value;
        setFormData({
          ...formData,
          vat: vatvalue,
          total: value,
          vattotal: vattotal,
        });
        break;
      case "vatrate":
        vatvalue = formData.total * (value / 100);
        vattotal = vatvalue + formData.total;
        setFormData({
          ...formData,
          vat: vatvalue,
          vatrate: value,
          vattotal: vattotal,
        });
        break;
      default:
        setFormData({ ...formData, [name]: value });
        break;
    }
  };

  const resetForm = () => {
    setFormData({
      eventtime: "",
      vehicle_id: 0,
      company_id: 0,
      location_id: 0,
      costtype_id: 0,
      currency: "EUR",
      quantity: 1,
      total: 0.0,
      vatrate: 0.0,
      vat: 0.0,
      vattotal: 0.0,
    });
  };

  useEffect(() => {
    /*const gLocations = async () => {
      props.getLocations();
    };*/
    const getCostTypes = async () => {
      props.getCostTypes();
    };
    //gLocations();
    getCostTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const vehicleList = props.vehicles.map((x) => {
    return { value: x.id, text: `${x.brand} - ${x.reg_number}` };
  });
  const companyList = props.companies.map((x) => {
    return { value: x.id, text: `${x.name}` };
  });
  /*const locationList = props.locations.map((x) => {
    return { value: x.id, text: `${x.country} - ${x.name}` };
  });*/
  //const locationList=[{value:0,text:"plop"}];
  const costtypesList = props.costtypes.map((x) => {
    return { value: x.id, text: `${x.code || ""} - ${x.name}` };
  });
  let errorMessages = <div></div>;
  if (errorList.length > 0) {
    errorMessages = (
      <Message negative>
        <Message.Header>{t("input.costform.errors.title")}</Message.Header>
        <List bulleted>
          {errorList.map((x, i) => {
            return <List.Item key={i}>{x}</List.Item>;
          })}
        </List>
      </Message>
    );
  }
  return (
    <Card fluid className="inputcostform">
      <Card.Content>
        <Card.Header>{t("input.costform.manualcost_title")}</Card.Header>
      </Card.Content>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("input.costform.datetime_eventtime")}</label>
              <DateTimeInput
                name="eventtime"
                placeholder="Date Time"
                value={formData.eventtime}
                popupPosition="bottom center"
                clearable={true}
                dateFormat="DD.MM.YYYY"
                duration={0}
                animation="none"
                onChange={onDateTimeChanged}
              />
            </Form.Field>
            <Form.Dropdown
              required
              id="inputcostform-vehicle"
              label={t("input.costform.select_vehicle")}
              fluid
              search
              selection
              options={vehicleList}
              value={formData.vehicle_id}
              onChange={onDropDownChanged}
              lazyLoad={true}
              name="vehicle_id"
            />
            <Form.Dropdown
              required
              id="inputcostform-company"
              label={t("input.costform.select_company")}
              fluid
              search
              selection
              options={companyList}
              value={formData.company_id}
              onChange={onDropDownChanged}
              lazyLoad={true}
              name="company_id"
            />
            {/*<Form.Dropdown
              required
              id="inputcostform-location"
              label={t("input.costform.select_location")}
              fluid
              search
              selection
              options={locationList}
              value={formData.location_id}
              onChange={onDropDownChanged}
              lazyLoad={true}
              name="location_id"
            />*/}
            <Form.Dropdown
              required
              id="inputcostform-costtype"
              label={t("input.costform.select_costtype")}
              fluid
              search
              selection
              options={costtypesList}
              value={formData.costtype_id}
              lazyLoad={true}
              onChange={onDropDownChanged}
              name="costtype_id"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              id="inputcostform-currency"
              value={formData.currency}
              label={t("input.costform.input_currency")}
              onChange={(e, x) => onFinancialDataChanged(x.value, "currency")}
              fluid
            />
            <Form.Field>
              <label>{t("input.costform.input_quantity")}</label>
              <InputNumber
                id="inputcostform-quantity"
                step={1}
                value={formData.quantity}
                precision={0}
                onChange={(e) => onFinancialDataChanged(e, "quantity")}
              />
            </Form.Field>
            <Form.Field required>
              <label>{t("input.costform.input_total")}</label>
              <InputNumber
                id="inputcostform-total"
                step={0.01}
                value={formData.total}
                precision={2}
                decimalSeparator={t("decimalcomma")}
                onChange={(e) => onFinancialDataChanged(e, "total")}
              />
            </Form.Field>
            <Form.Field>
              <label>{t("input.costform.input_vatrate")}</label>
              <InputNumber
                id="inputcostform-vat-rate"
                step={1}
                value={formData.vatrate}
                precision={0}
                decimalSeparator={t("decimalcomma")}
                onChange={(e) => onFinancialDataChanged(e, "vatrate")}
              />
            </Form.Field>
            <Form.Field>
              <label>{t("input.costform.input_vat")}</label>
              <InputNumber
                id="inputcostform-vat"
                step={0.01}
                value={formData.vat}
                precision={2}
                decimalSeparator={t("decimalcomma")}
                onChange={(e) => onFinancialDataChanged(e, "vat")}
              />
            </Form.Field>
            <Form.Field>
              <label>{t("input.costform.input_vattotal")}</label>
              <InputNumber
                id="inputcostform-vat-total"
                step={0.01}
                value={formData.vattotal}
                precision={2}
                decimalSeparator={t("decimalcomma")}
                onChange={(e) => onFinancialDataChanged(e, "vattotal")}
              />
            </Form.Field>
          </Form.Group>
          {errorMessages}
          <ActionButton
            text={t("input.costform.button_save")}
            size="large"
            icon="save"
            onClick={onSaveCost}
          />
        </Form>
      </Card.Content>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  vehicles: state.input.vehicles,
  companies: state.input.companies,
  locations: state.input.locations,
  costtypes: state.input.costtypes,
});
const mapDispatchToProps = (dispatch) => ({
  getLocations: () => dispatch(fetchLocations()),
  getCostTypes: () => dispatch(fetchCostTypes()),
  addCost: (costdata) => dispatch(insertCost(costdata)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InputCostForm);
