import React from "react";
import { connect } from "react-redux";
import { Card, Header, Image, Grid } from "semantic-ui-react";

//import Components
import CSVCheckResult from "components/CSVCheckResult";
import ActionButton from "components/ActionButton";

//Import Actions
import { confirmCSV } from "actions/inputActions";

//Import styles
import "./CSVCheck.scss";

//Import images
import unknownlogo from "images/brands/unknown.png";
import utalogo from "images/brands/uta.png";
import dsvlogo from "images/brands/dsv.png";
import asterilogo from "images/brands/asteri.png";

function CSVCheck(props) {
  const validateCSV = (e) => {
    props.validateJSON(props.checkResult.fileID);
  };

  if (!props.checkResult.fileName) {
    return <div></div>;
  }
  let csvlogo = unknownlogo;
  switch (props.checkResult.fileType.toLowerCase()) {
    case "uta":
      csvlogo = utalogo;
      break;
    case "dsv":
      csvlogo = dsvlogo;
      break;
    case "asteri":
      csvlogo = asterilogo;
      break;
    default:
      break;
  }
  let validateButton = <div></div>;
  //loop thru checklist, if there is no error, we can validate the json
  if (!props.checkResult.checkList.find((x) => x.type === "error")) {
    validateButton = (
      <ActionButton
        text="Validate"
        size="large"
        icon="check"
        onClick={validateCSV}
      />
    );
  }
  return (
    <Card fluid className="csvcheck">
      <Card.Content>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width="12" verticalAlign="middle">
              <Header as="h2">
                {props.checkResult.fileName}
                <Header.Subheader>{props.checkResult.fileID}</Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column width="4">
              <Image
                size="tiny"
                src={csvlogo}
                className="csvlogo"
                floated="right"
                verticalAlign="middle"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>

      <Card.Content>
        {props.checkResult.checkList.map((x, i) => {
          return <CSVCheckResult result={x} key={i} />;
        })}
        {validateButton}
      </Card.Content>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  checkResult: state.input.csvCheckResult,
});
const mapDispatchToProps = (dispatch) => ({
  validateJSON: (filename) => dispatch(confirmCSV(filename)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CSVCheck);
