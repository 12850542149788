
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';

//Import semantic-ui
import 'semantic-ui-react';

//Import CSS from semantic-UI
import 'semantic-ui-css/semantic.min.css';
//Import CSS from toastify
import 'react-toastify/dist/ReactToastify.css';
//Import CSS from 
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
//Import CSS custom styles
import './styles/theme.scss'

//Auth
import { Auth0Provider } from "@auth0/auth0-react";
import history from "utils/history";
import './ReactotronConfig'


// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    issuer={process.env.REACT_APP_AUTH0_ISSUER}
    onRedirectCallback={onRedirectCallback}>
    <App />
  </Auth0Provider>, document.getElementById('root'));