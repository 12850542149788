import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Menu } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import "./Navbar.scss";
import mslogo from "images/logo_ms.png";

//Component imports
import NavbarAuth from "components/NavbarAuth";

function Navbar(props) {
  const { t } = useTranslation();
  const isActive = (val, route) => {
    return val.pathname.startsWith(route);
  };
  return (
    <Menu inverted className="navbar">
      <Menu.Item className="noBorderHover logo">
        <a href="/">
          <img src={mslogo} alt="ms logo" />
        </a>
      </Menu.Item>
      {props.user.email && (
        <>
          <NavLink
            className="navbarLink"
            to="/reports"
            isActive={(e, val) => isActive(val, "/reports")}
          >
            <Menu.Item name="reports">{t("navbar.reports")}</Menu.Item>
          </NavLink>
          <NavLink
            className="navbarLink"
            to="/input"
            isActive={(e, val) => isActive(val, "/input")}
          >
            <Menu.Item name="input">{t("navbar.input")}</Menu.Item>
          </NavLink>
          <NavLink
            className="navbarLink"
            to="/data"
            isActive={(e, val) => isActive(val, "/data")}
          >
            <Menu.Item name="data">{t("navbar.data")}</Menu.Item>
          </NavLink>
          <NavLink
            className="navbarLink"
            to="/service"
            isActive={(e, val) => isActive(val, "/service")}
          >
            <Menu.Item name="service">{t("navbar.service")}</Menu.Item>
          </NavLink>
        </>
      )}
      <NavbarAuth />
    </Menu>
  );
}

const mapStateToProps = (state) => ({
  user: state.root.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
