import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Table, Button, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { getStatusIcon, getStatus, getIcon } from "utils/serviceUtils";
import { compareNewestService } from "utils/serviceUtils";

import moment from "moment";

//Import Styles
import "./Service.scss";

import { modalConfirm } from "actions/rootActions";

import { fetchVehiclesServiceData } from "actions/serviceActions";

//Import utils
import { kmData } from "utils/utils";

//Import constants
import {
  STATUS_SERVICE_OK,
  STATUS_SERVICE_APPROACHING,
  STATUS_SERVICE_OVERDUE,
} from "definitions/constants";

//https://github.com/diogofcunha/react-virtualized-tree/
const Service = ({ servicevehicles, getServiceData }) => {
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    const gServiceData = async () => {
      getServiceData();
    };
    gServiceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditClick = (e, { id }) => {
    // Find the related object
    const data = servicevehicles.find((elem) => elem.id === id);
    history.push("/serviceview", { data });
  };

  const handleViewStatus = (e, { id }) => {
    const data = servicevehicles.find((elem) => elem.id === id);
    history.push("/vehiclestatus", { data });
  };

  const checkStatus = (CHK, nextService) => {
    if (CHK < 0) {
      return getIcon("overdue");
    } else {
      if (CHK > nextService) return getIcon("ok");
      if (CHK < nextService) return getIcon("soon");
    }

    return t("axelstatus.notset");
  };

  return (
    <div className="main">
      <h1>{t("service.vehicle_list")}</h1>
      <Table compact celled padded striped singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="2">
              {t("service.header_reg_number")}
            </Table.HeaderCell>
            <Table.HeaderCell width="2">
              {t("service.header_vehicle_id")}
            </Table.HeaderCell>
            <Table.HeaderCell width="2">
              {t("service.header_make")}
            </Table.HeaderCell>
            <Table.HeaderCell width="1">
              {t("service.header_model")}
            </Table.HeaderCell>
            <Table.HeaderCell width="1">
              {t("service.header_year")}
            </Table.HeaderCell>
            <Table.HeaderCell width="2">
              {t("service.header_total_mileage")}
            </Table.HeaderCell>
            <Table.HeaderCell width="2">
              {t("service.header_last_service")}
            </Table.HeaderCell>
            <Table.HeaderCell width="2">
              {t("service.header_next_service")}
            </Table.HeaderCell>
            <Table.HeaderCell width="1">
              {t("service.header_status")}
            </Table.HeaderCell>
            <Table.HeaderCell width="1">
              {t("service.header_edit")}
            </Table.HeaderCell>
            <Table.HeaderCell width="1">
              {t("service.header_see_detail")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {servicevehicles.map((x) => {
            return (
              <Table.Row key={x.id}>
                <Table.Cell>{x.reg_number}</Table.Cell>
                <Table.Cell>{x.VIN}</Table.Cell>
                <Table.Cell>{x.brand}</Table.Cell>
                <Table.Cell>{x.model}</Table.Cell>
                <Table.Cell>{x.year}</Table.Cell>
                <Table.Cell>{kmData(x.total_distance)}</Table.Cell>
                <Table.Cell>{compareNewestService(x)}</Table.Cell>
                <Table.Cell>
                  {compareNewestService(x) + x.serviceInterval}
                </Table.Cell>
                <Table.Cell>
                  {checkStatus(
                    compareNewestService(x) +
                      x.serviceInterval -
                      x.total_distance / 1000,
                    x.nextservicealert
                  )}
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    compact
                    color="green"
                    onClick={handleEditClick}
                    id={x.id}
                  >
                    <Button.Content visible>
                      <Icon name="pencil" />
                    </Button.Content>
                  </Button>
                </Table.Cell>
                <Table.Cell>
                  <Button color="green" onClick={handleViewStatus} id={x.id}>
                    <Button.Content>
                      <Icon name={"eye"} />
                    </Button.Content>
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  servicevehicles: state.service.servicevehicles,
});
const mapDispatchToProps = (dispatch) => ({
  getServiceData: () => dispatch(fetchVehiclesServiceData()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Service);
