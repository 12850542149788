import produce from "immer";

//Import actions
import {GET_VEHICLES_SUCCESS} from 'actions/dashboardActions';

const initialState={
    vehicles: []
};

const dashboardReducer = (state=initialState,action)=>
    produce(state,draft=>{
        switch(action.type){
            case GET_VEHICLES_SUCCESS:
                draft.vehicles=action.vehicles;
                break;
            default:
                break;
        }
    });

export default dashboardReducer;