import i18n from "i18n";
//Import Actions
import { loading, modalError, addToast } from "./rootActions";
//Import API
import {
  checkCSV,
  validateCSV,
  getVehicles,
  getLocations,
  getCompanies,
  getCostTypes,
  addCost,
  getRevenueTypes,
  addRevenue,
  getUnlinkedRevenues,
  linkVehicleToRevenue,
  getCostGroups,
  getRevenueGroups,
  getUngroupedRevenueTypes,
  getUngroupedCostTypes,
  linkCosttypesToGroup,
  linkRevenuetypesToGroup,
} from "api/backendAPI";

export const CHECK_CSV_SUCCESS = "CHECK_CSV_SUCCESS";
export const CONFIRM_CSV_SUCCESS = "CONFIRM_CSV_SUCCESS";

export const GET_VEHICLES_SUCCESS = "GET_VEHICLES_SUCCESS";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COSTTYPES_SUCCESS = "GET_COSTTYPES_SUCCESS";
export const ADD_COST_SUCCESS = "ADD_COST_SUCCESS";
export const GET_REVENUETYPES_SUCCESS = "GET_REVENUETYPES_SUCCESS";
export const ADD_REVENUE_SUCCESS = "ADD_REVENUE_SUCCESS";

export const GET_UNLINKED_REVENUES_SUCCESS = "GET_UNLINKED_REVENUES_SUCCESS";
export const LINK_VEHICLE_TO_REVENUE_SUCCESS =
  "LINK_VEHICLE_TO_REVENUE_SUCCESS";

export const GET_COSTGROUPS_SUCCESS = "GET_COSTGROUPS_SUCCESS";
export const GET_REVENUEGROUPS_SUCCESS = "GET_REVENUEGROUPS_SUCCESS";
export const GET_UNGROUPEDCOSTTYPES_SUCCESS = "GET_UNGROUPEDCOSTTYPES_SUCCESS";
export const GET_UNGROUPEDREVENUETYPES_SUCCESS =
  "GET_UNGROUPEDREVENUETYPES_SUCCESS";

function inspectCSV(fileData) {
  return (dispatch) => {
    dispatch(loading(true));
    return checkCSV(fileData)
      .then((checkResult) => {
        dispatch(loading(false));
        dispatch(checkCSVSuccess(checkResult));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
        dispatch(checkCSVSuccess({}));
      });
  };
}

function confirmCSV(fileName) {
  return (dispatch) => {
    dispatch(loading(true));
    return validateCSV(fileName)
      .then((result) => {
        dispatch(loading(false));
        dispatch(confirmCSVSuccess(result));
        dispatch(
          addToast({
            type: "success",
            message: i18n.t("input.csvform.toast_csv_processed"),
          })
        );
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function fetchVehicles() {
  return (dispatch) => {
    dispatch(loading(true));
    return getVehicles()
      .then((vehicles) => {
        dispatch(loading(false));
        dispatch(fetchVehiclesSuccess(vehicles));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function fetchVehiclesSuccess(vehicles) {
  return {
    type: GET_VEHICLES_SUCCESS,
    vehicles,
  };
}

function fetchCompanies() {
  return (dispatch) => {
    dispatch(loading(true));
    return getCompanies()
      .then((companies) => {
        dispatch(loading(false));
        dispatch(fetchCompaniesSuccess(companies));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function fetchCompaniesSuccess(companies) {
  return {
    type: GET_COMPANIES_SUCCESS,
    companies,
  };
}

function fetchLocations() {
  return (dispatch) => {
    dispatch(loading(true));
    return getLocations()
      .then((locations) => {
        dispatch(loading(false));
        dispatch(fetchLocationsSuccess(locations));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function fetchLocationsSuccess(locations) {
  return {
    type: GET_LOCATIONS_SUCCESS,
    locations,
  };
}

function fetchCostTypes() {
  return (dispatch) => {
    dispatch(loading(true));
    return getCostTypes()
      .then((costtypes) => {
        dispatch(loading(false));
        dispatch(fetchCostTypesSuccess(costtypes));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function fetchCostTypesSuccess(costtypes) {
  return {
    type: GET_COSTTYPES_SUCCESS,
    costtypes,
  };
}

function fetchRevenueTypes() {
  return (dispatch) => {
    dispatch(loading(true));
    return getRevenueTypes()
      .then((revenuetypes) => {
        dispatch(loading(false));
        dispatch(fetchRevenueTypesSuccess(revenuetypes));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function fetchRevenueTypesSuccess(revenuetypes) {
  return {
    type: GET_REVENUETYPES_SUCCESS,
    revenuetypes,
  };
}

function insertCost(costdata) {
  return (dispatch) => {
    dispatch(loading(true));
    return addCost(costdata)
      .then((x) => {
        dispatch(loading(false));
        dispatch(
          addToast({
            type: "success",
            message: i18n.t("input.costform.toast_cost_added"),
          })
        );
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function insertRevenue(revenuedata) {
  return (dispatch) => {
    dispatch(loading(true));
    return addRevenue(revenuedata)
      .then((x) => {
        dispatch(loading(false));
        dispatch(
          addToast({
            type: "success",
            message: i18n.t("input.revenueform.toast_revenue_added"),
          })
        );
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function checkCSVSuccess(result) {
  return {
    type: CHECK_CSV_SUCCESS,
    result,
  };
}
function confirmCSVSuccess(result) {
  return {
    type: CONFIRM_CSV_SUCCESS,
    result,
  };
}

function fetchUnlinkedRevenues() {
  return (dispatch) => {
    dispatch(loading(true));
    return getUnlinkedRevenues()
      .then((unlinkedrevenues) => {
        dispatch(loading(false));
        dispatch(fetchUnlinkedRevenuesSuccess(unlinkedrevenues));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function fetchUnlinkedRevenuesSuccess(unlinkedrevenues) {
  return {
    type: GET_UNLINKED_REVENUES_SUCCESS,
    unlinkedrevenues,
  };
}

function addLinkVehicleToRevenue(linkData) {
  return (dispatch) => {
    dispatch(loading(true));
    return linkVehicleToRevenue(linkData)
      .then((x) => {
        dispatch(loading(false));
        dispatch(
          addToast({
            type: "success",
            message: i18n.t("input.linkrevenuesform.toast_link_added"),
          })
        );
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

/*
  getUngroupedRevenueTypes,
  getUngroupedCostTypes,
  linkCosttypesToGroup,
  linkRevenuetypesToGroup,
*/

function fetchCostGroups() {
  return (dispatch) => {
    dispatch(loading(true));
    return getCostGroups()
      .then((costgroups) => {
        dispatch(loading(false));
        dispatch(fetchCostGroupsSuccess(costgroups));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function fetchCostGroupsSuccess(costgroups) {
  return {
    type: GET_COSTGROUPS_SUCCESS,
    costgroups,
  };
}

function fetchRevenueGroups() {
  return (dispatch) => {
    dispatch(loading(true));
    return getRevenueGroups()
      .then((revenuegroups) => {
        dispatch(loading(false));
        dispatch(fetchRevenuesGroupsSuccess(revenuegroups));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function fetchRevenuesGroupsSuccess(revenuegroups) {
  return {
    type: GET_REVENUEGROUPS_SUCCESS,
    revenuegroups,
  };
}

function fetchUngroupedCostTypes() {
  return (dispatch) => {
    dispatch(loading(true));
    return getUngroupedCostTypes()
      .then((ungroupedcosttypes) => {
        dispatch(loading(false));
        dispatch(fetchUngroupedCostTypesSuccess(ungroupedcosttypes));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function fetchUngroupedCostTypesSuccess(ungroupedcosttypes) {
  return {
    type: GET_UNGROUPEDCOSTTYPES_SUCCESS,
    ungroupedcosttypes,
  };
}

function fetchUngroupedRevenueTypes() {
  return (dispatch) => {
    dispatch(loading(true));
    return getUngroupedRevenueTypes()
      .then((ungroupedrevenuetypes) => {
        dispatch(loading(false));
        dispatch(fetchUngroupedRevenueTypesSuccess(ungroupedrevenuetypes));
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function fetchUngroupedRevenueTypesSuccess(ungroupedrevenuetypes) {
  return {
    type: GET_UNGROUPEDREVENUETYPES_SUCCESS,
    ungroupedrevenuetypes,
  };
}

function addGroupToCostType(linkData) {
  return (dispatch) => {
    dispatch(loading(true));
    return linkCosttypesToGroup(linkData)
      .then(() => {
        dispatch(loading(false));
        dispatch(
          addToast({
            type: "success",
            message: i18n.t("input.linkungroupedcosttypes.toast_link_added"),
          })
        );
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

function addGroupToRevenueType(linkData) {
  return (dispatch) => {
    dispatch(loading(true));
    return linkRevenuetypesToGroup(linkData)
      .then(() => {
        dispatch(loading(false));
        dispatch(
          addToast({
            type: "success",
            message: i18n.t("input.linkungroupedrevenuetypes.toast_link_added"),
          })
        );
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(modalError(err));
      });
  };
}

export {
  inspectCSV,
  confirmCSV,
  fetchVehicles,
  fetchCompanies,
  fetchCostTypes,
  fetchLocations,
  insertCost,
  fetchRevenueTypes,
  insertRevenue,
  fetchUnlinkedRevenues,
  addLinkVehicleToRevenue,
  fetchCostGroups,
  fetchRevenueGroups,
  fetchUngroupedCostTypes,
  fetchUngroupedRevenueTypes,
  addGroupToCostType,
  addGroupToRevenueType,
};
